import onDocumentComplete from '../../../scripts/utils/on-document-complete';

function animateTeaserStageOrnament() {
    const ornaments = [...document.querySelectorAll('.c-hswt-ornament')];

    // Sanity check
    if (!ornaments.length) return;

    // Create a callback that will be called
    // when the target element is intersected.
    function handleIntersection(entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animate-in');
                observer.unobserve;
            }
        });
    }

    // Instantiate the observer
    let ornamentObserver = new IntersectionObserver(handleIntersection);

    // Start observing
    ornaments.forEach(function (ornament) {
        ornamentObserver.observe(ornament);
    });
}

export default animateTeaserStageOrnament;
