import trapFocus from '../../../scripts/modules/trap-focus';

/**
 * @class Dialog
 */
class Dialog {
    $element;
    id;
    previouslyFocused = null;
    isShown = false;

    constructor(element) {
        this.$element = element;
        this.id = this.$element.getAttribute('data-dialog') || this.$element.id;

        this.$element.setAttribute('aria-hidden', 'true');
        this.$element.setAttribute('aria-modal', 'true');
        this.$element.setAttribute('tabindex', '-1');

        if (!this.$element.hasAttribute('role', 'alertdialog')) {
            this.$element.setAttribute('role', 'dialog');
        }

        document.addEventListener('click', this.handleTriggerClick, true);
    }

    /**
     * Show the dialog element, trap the current focus within it, listen for some
     * specific key presses and fire all registered callbacks for `show` event.
     *
     * @param {Event} event
     */
    show = (event) => {
        // If the dialog is already open, abort
        if (this.isShown) return this;

        // Keep a reference to the currently focused element
        // to be able to restore it later.
        this.previouslyFocused = document.activeElement;
        this.isShown = true;
        this.$element.removeAttribute('aria-hidden');

        // Set the focus to the dialog element.
        moveFocusToDialog(this.$element);

        document.body.addEventListener('focus', this.handleOutsideFocus, true);
        this.$element.addEventListener('keydown', this.handleKeyDown, true);

        // Dispatch a `show` event
        this.dispatch('show', event);

        return this;
    };

    /**
     * Hide the dialog element, restore the focus to the previously
     * active element, stop listening for some specific key presses
     * and fire all registered callbacks for `hide` event.
     *
     * @param {Event} event
     */
    hide = (event) => {
        // If the dialog is already closed, abort
        if (!this.isShown) return this;

        this.isShown = false;

        this.$element.setAttribute('aria-hidden', 'true');

        // Set the focus to the previously focused element
        this.previouslyFocused
            ? this.previouslyFocused.focus()
            : document.body.focus();

        // Stop listening for the events we only care for while the dialog is shown.
        document.body.removeEventListener(
            'focus',
            this.handleOutsideFocus,
            true
        );
        this.$element.removeEventListener('keydown', this.handleKeyDown, true);

        // Dispatch a `hide` event
        this.dispatch('hide', event);

        return this;
    };

    /**
     * Destroy the current instance after making sure the dialog has been hidden
     * all associated listeners were removed from dialog openers and closers.
     */
    destroy = () => {
        // Hide the dialog to avoid destroying an open instance
        this.hide();

        // Remove the click event delegates for our openers and closers
        document.removeEventListener('click', this.handleTriggerClick, true);

        // Dispatch a `destroy` event
        this.dispatch('destroy');

        return this;
    };

    /**
     * Dispatch a custom event from the DOM element associated with this dialog.
     * This allows authors to listen for and respond to the events in their
     * own code.
     *
     * @param {String} eventName - The custom event name to dispatch.
     * @param {Event} event
     */
    dispatch = (eventName, event) => {
        this.$element.dispatchEvent(
            new CustomEvent(eventName, {
                detail: event,
                cancelable: true,
            })
        );
    };

    /**
     * Register a new callback for the given event.
     *
     * @param {String='show','hide','destroy'} eventName - The event to register the callback for.
     * @param {Function} callback - The callback function to execute when the given event is fired.
     * @param {(Object|Boolean)} [options]
     */
    on = (eventName, callback, options) => {
        this.$element.addEventListener(eventName, callback, options);
        return this;
    };

    /**
     * Unregister an existing callback for the given event.
     *
     * @param {String='show','hide','destroy'} eventName - The event to unregister the callback for.
     * @param {Function} callback
     * @param {(Object|Boolean)} [options]
     */
    off = (eventName, callback, options) => {
        this.$element.removeEventListener(eventName, callback, options);
        return this;
    };

    /**
     * Add a delegated event listener for when elements that open or close
     * the dialog are clicked, and call `show` or `hide`, respectively.
     *
     * @param {Event}
     */
    handleTriggerClick = (event) => {
        const target = event.target;
        if (target.matches(`[data-dialog-show="${this.id}"]`)) {
            this.show(event);
        }
        if (
            target.matches(`[data-dialog-hide="${this.id}"]`) ||
            (target.matches('[data-dialog-hide]') &&
                target.closest('[aria-modal="true"]') === this.$element)
        ) {
            this.hide(event);
        }
    };

    handleKeyDown = (event) => {
        // This is an escape hatch in case there are nested open dialogs, so that
        // only the top most dialog gets interacted with
        if (
            document.activeElement?.closest('[aria-modal="true"]') !==
            this.$element
        ) {
            return;
        }

        // If the dialog is shown and the ESC key is pressed, prevent any further
        // effects from the ESC key and hide the dialog, unless its role is
        // `alertdialog`, which should be modal
        if (
            event.key === 'Escape' &&
            this.$element.getAttribute('role') !== 'alertdialog'
        ) {
            event.preventDefault();
            this.hide(event);
        }
        // If the dialog is shown and the TAB key is pressed, make sure the focus
        // stays trapped within the dialog element
        if (event.key === 'Tab') {
            trapFocus(this.$element, event);
        }
    };

    /**
     * Make sure the focus stays trapped inside the dialog.
     *
     * If anything outside the dialog (or another dialog in case of nested dialogs)
     * receives focus while the dialog is shown, move focus back to the dialog.
     *
     * @see https://github.com/KittyGiraudel/a11y-dialog/issues/177
     *
     * @param {FocusEvent} event
     */
    handleOutsideFocus = (event) => {
        if (
            !event.target.closest(
                '[aria-modal="true"], [data-dialog-ignore-focus-trap]'
            )
        )
            moveFocusToDialog(this.$element);
    };
}

/**
 * Set the focus to the first element with `autofocus` with the element
 * or the element itself.
 *
 * @param {HTMLElement} element
 */
function moveFocusToDialog(element) {
    const focusedElement = element.querySelector('[autofocus]') || element;
    focusedElement.focus();
}

export default Dialog;
