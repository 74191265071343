class Breadcrumb {
    constructor($module) {
        this.$breadcrumb = $module;
        this.breadcrumbScrollWidth;
        this.resizeTimer;

        this.executeTransition = this.executeTransition.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onTransitionEnd = this.onTransitionEnd.bind(this);
    }

    init() {
        window.addEventListener('DOMContentLoaded', this.executeTransition);
        window.addEventListener('resize', this.onResize);
    }

    executeTransition() {
        // wait 200 ms before transitioning
        setTimeout(() => {
            // fake scrolling animation by executing transition when DOM content is loaded
            window.removeEventListener(
                'DOMContentLoaded',
                this.executeTransition
            );

            const breadcrumbWidth = this.$breadcrumb.offsetWidth;
            this.breadcrumbScrollWidth = this.$breadcrumb.scrollWidth;
            const scrollOffset = this.breadcrumbScrollWidth - breadcrumbWidth;

            // if breadcrumb content is not wider than its container, do nothing
            if (scrollOffset <= 0) {
                return;
            }

            this.$breadcrumb.classList.add('breadcrumb__list--in-transition');

            // make breadcrumb scrollable when transition is finished
            this.$breadcrumb.addEventListener(
                'transitionend',
                this.onTransitionEnd
            );

            // Bugfix for Safari
            this.$breadcrumb.style.overflow = 'hidden';

            // wait for the current call stack to finish before setting inline styles
            setTimeout(() => {
                this.$breadcrumb.style.transform =
                    'translateX(-' + scrollOffset + 'px)';

                // Bugfix for Safari
                this.$breadcrumb.style.overflow = null;
            }, 0);
        }, 200);

    }
    onTransitionEnd() {
        console.log('onTransitionEnd');
        this.$breadcrumb.removeEventListener(
            'transitionend',
            this.onTransitionEnd
        );
        this.$breadcrumb.classList.remove('breadcrumb__list--in-transition');
        this.$breadcrumb.style.transform = null;
        this.$breadcrumb.scrollLeft = this.breadcrumbScrollWidth;
    }
    onResize() {
        // trigger transition after resize with debounce
        clearTimeout(this.resizeTimer);
        this.resizeTimer = setTimeout(
            () => {
                this.executeTransition();
            },
            250
        );
    }
}

export default Breadcrumb;
