/* eslint-disable no-console */

import { html, nothing } from 'lit';
import { map } from 'lit/directives/map.js';
import { join } from 'lit/directives/join.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';
import { prettyNumericDate, limitString } from '../utils.js';

class LfListItem extends BaseCustomElement {
    static get properties() {
        return {
            item: { type: Object },
            excerptLimit: { type: Number },
            isLoading: { type: Boolean },
            isFilteredByLastName: { type: Boolean },
            isAuthorMetaOverflowing: { type: Boolean },
            isAuthorMetaExpanded: { type: Boolean },
        };
    }

    constructor() {
        super();
        this.item = {
            abstract: '',
            categories: [],
            detailUrl: '',
            headline: '',
            endDateList: '',
            endTimeList: '',
            degree: {
                title: '',
            },
            title: '',
            introtext: '',
            status: '',
            teasertext: '',
            personsList: [],
            personsListTitle: '',
            startDateList: '',
            startTimeList: '',
        };
        this.i18n = {};
        this.isExpanded = false;
        this.excerptLimit = 240;
        this.variant = '';
        this.isFilteredByLastName = false;
        this.isAuthorMetaOverflowing = false;
        this.isAuthorMetaExpanded = false;
    }

    updated(changed) {
        this._checkAuthorsOverflow();
    }

    _prettyDateOrDuration(startDate, endDate) {
        return html`${startDate
            ? html`<time datetime="${startDate}"
                  >${prettyNumericDate(startDate)}</time
              >`
            : nothing}${startDate && endDate ? html` – ` : nothing}${endDate
            ? html`<time datetime="${endDate}"
                  >${prettyNumericDate(endDate)}</time
              >`
            : nothing}`;
    }

    _checkAuthorsOverflow() {
        function isOverflowing(element) {
            if (!element) {
                return;
            }
            return (
                element.scrollWidth > element.clientWidth ||
                element.scrollHeight > element.clientHeight
            );
        }

        const $authorMeta = this.querySelector('.js-overflow-check');
        this.isAuthorMetaOverflowing = isOverflowing($authorMeta);
    }

    _onExpandClick(event) {
        const $toggle = event.target;
        const isExpanded = $toggle.getAttribute('aria-expanded') === 'true';
        $toggle.setAttribute('aria-expanded', !isExpanded);
        this.isAuthorMetaExpanded = !isExpanded;
    }

    // Templates
    // ------------------------------------------------------------------------

    _factsListTemplate() {
        return html`
            <dl
                class="c-facts-list c-facts-list--sm u-margin-top-1 u-margin-bottom-2"
            >
                ${this.item.doctoralCandidate
                    ? html`
                          <div class="o-grid c-facts-list__item">
                              <dt
                                  class="c-facts-list__term u-color-primary o-grid__col--12 o-grid__col--4@lg"
                              >
                                  ${this.i18n.doctoralCandidate}
                              </dt>
                              <dd
                                  class="c-facts-list__description u-color-text o-grid__col--12 o-grid__col--8@lg"
                              >
                                  ${this.item.doctoralCandidate.fullname}
                                  ${join(
                                      map(
                                          this.item.doctoralCandidate,
                                          (candidate) =>
                                              html`${candidate.fullname}`
                                      ),
                                      html`, `
                                  )}
                              </dd>
                          </div>
                      `
                    : nothing}
                ${this.item.supervisorInternal
                    ? html`
                          <div class="o-grid c-facts-list__item">
                              <dt
                                  class="c-facts-list__term u-color-primary o-grid__col--12 o-grid__col--4@lg"
                              >
                                  ${this.i18n.supervisorInternal}
                              </dt>
                              <dd
                                  class="c-facts-list__description u-color-text o-grid__col--12 o-grid__col--8@lg"
                              >
                                  ${join(
                                      map(
                                          this.item.supervisorInternal,
                                          (supervisor) =>
                                              html`${supervisor.fullname}`
                                      ),
                                      html`, `
                                  )}
                              </dd>
                          </div>
                      `
                    : nothing}
                ${this.item.startDateList || this.item.endDateList
                    ? html`
                          <div class="o-grid c-facts-list__item">
                              <dt
                                  class="c-facts-list__term u-color-primary o-grid__col--12 o-grid__col--4@lg"
                              >
                                  ${this.i18n.duration ?? nothing}
                              </dt>
                              <dd
                                  class="c-facts-list__description u-color-text o-grid__col--12 o-grid__col--8@lg"
                              >
                                  ${this._prettyDateOrDuration(
                                      this.item.startDateList,
                                      this.item.endDateList
                                  )}
                              </dd>
                          </div>
                      `
                    : nothing}
            </dl>
        `;
    }

    _metaTemplate() {
        const hasDate =
            !this.item.dateRange &&
            (this.item.startDateList || this.item.endDateList);

        const hasTime = this.item.startTimeList || this.item.endTimeList;

        const isPromotion =
            this.item.supervisorExternal ||
            this.item.supervisorExternalPartner ||
            this.item.supervisorInternal;

        const isVariantJobs = this.variant === 'jobs';
        const isVariantEvents = this.variant === 'events';
        const isVariantProjects = this.variant === 'projects';

        const isLabelShow =
            this.variant !== 'jobs' && this.variant !== 'projects'
                ? ' u-sr-only'
                : '';
        const isItemTag =
            this.variant === 'events' || this.variant === 'furthereducations'
                ? ' c-meta__item--tag'
                : '';

        return html`
            <div class="c-meta">
                <ul class="c-meta__list u-color-primary">
                    <!-- date -->
                    ${this.item.dateList
                        ? html`
                              <li class="c-meta__item ${isItemTag}">
                                  <span class="c-meta__label ${isLabelShow}">
                                      ${this.i18n.duration}:
                                  </span>
                                  <time
                                      class="c-meta__text"
                                      datetime="${this.item.dateList}"
                                  >
                                      ${prettyNumericDate(this.item.dateList)}
                                  </time>
                              </li>
                          `
                        : nothing}
                    <!-- date range -->
                    ${this.item.dateRange
                        ? html`
                              <li class="c-meta__item ${isItemTag}">
                                  <span class="c-meta__label ${isLabelShow}">
                                      ${this.i18n.duration}:
                                  </span>
                                  <span class="c-meta__text">
                                      ${this.item.dateRange}
                                  </span>
                              </li>
                          `
                        : nothing}
                    <!-- date range js style -->
                    ${hasDate && !isPromotion
                        ? html`
                              <li class="c-meta__item ${isItemTag}">
                                  <span class="c-meta__label ${isLabelShow}">
                                      ${this.i18n.duration}:
                                  </span>
                                  <span class="c-meta__text">
                                      ${this.item.startDateList
                                          ? html`<time
                                                datetime="${this.item
                                                    .startDateList}"
                                                >${prettyNumericDate(
                                                    this.item.startDateList
                                                )}</time
                                            >`
                                          : nothing}${this.item.startDateList &&
                                      this.item.endDateList
                                          ? html` – `
                                          : nothing}${this.item.endDateList
                                          ? html`<time
                                                datetime="${this.item
                                                    .endDateList}"
                                                >${prettyNumericDate(
                                                    this.item.endDateList
                                                )}</time
                                            >`
                                          : nothing}
                                  </span>
                              </li>
                          `
                        : nothing}
                    <!-- date range js style -->
                    ${hasTime
                        ? html`
                              <li class="c-meta__item">
                                  <span class="c-meta__label u-sr-only">
                                      ${this.i18n.time}:
                                  </span>
                                  <span class="c-meta__text">
                                      ${this.item.startTimeList}
                                      ${this.item.startTimeList &&
                                      this.item.endTimeList
                                          ? html` bis ${this.item.endTimeList}`
                                          : nothing}
                                      Uhr
                                  </span>
                              </li>
                          `
                        : nothing}
                    ${this.item.degree
                        ? html`
                              <li class="c-meta__item">
                                  <span class="c-meta__label u-sr-only">
                                      ${this.i18n.degree}:
                                  </span>
                                  <span class="c-meta__text"
                                      >${this.item.degree}</span
                                  >
                              </li>
                          `
                        : nothing}
                    ${this.item.categories?.map(
                        (category) =>
                            html`
                                <li class="c-meta__item">
                                    <span class="c-meta__label u-sr-only">
                                        ${this.i18n.category}:
                                    </span>
                                    <span class="c-meta__text"
                                        >${category.title}</span
                                    >
                                </li>
                            `
                    )}
                    ${this.item.locations && isVariantEvents
                        ? html`
                              ${join(
                                  map(
                                      this.item.locations,
                                      (location) =>
                                          html` <li class="c-meta__item">
                                              ${location === 'Online'
                                                  ? html`<svg
                                                        class="c-icon c-icon--sm c-meta__icon"
                                                        aria-hidden="true"
                                                        focusable="false"
                                                    >
                                                        <use
                                                            xlink:href="/assets/icons/sprite.svg#online-24"
                                                        />
                                                    </svg>`
                                                  : html`<svg
                                                        class="c-icon c-icon--sm c-meta__icon"
                                                        aria-hidden="true"
                                                        focusable="false"
                                                    >
                                                        <use
                                                            xlink:href="/assets/icons/sprite.svg#pin-24"
                                                        />
                                                    </svg>`}

                                              <p
                                                  class="c-meta__label u-sr-only"
                                              >
                                                  ${this.i18n.location ??
                                                  nothing}
                                              </p>
                                              <span class="c-meta__text"
                                                  >${location.title ??
                                                  location}</span
                                              >
                                          </li>`
                                  ),
                                  html``
                              )}
                          `
                        : nothing}
                    ${this.item.jobtypes
                        ? html`
                              <li
                                  class="c-meta__item c-meta__item--tag u-background-color-blue-lightest"
                              >
                                  ${join(
                                      map(
                                          this.item.jobtypes,
                                          (jobtype) =>
                                              html`<span
                                                      class="c-meta__label u-sr-only"
                                                      >${this.i18n
                                                          .jobtype}: </span
                                                  ><span class="c-meta__text"
                                                      >${jobtype.title}</span
                                                  >`
                                      ),
                                      html` / `
                                  )}
                              </li>
                          `
                        : nothing}
                    ${this.item.salaries
                        ? html`
                              <li
                                  class="c-meta__item c-meta__item--tag u-background-color-blue-lightest"
                              >
                                  ${join(
                                      map(
                                          this.item.salaries,
                                          (salary) =>
                                              html`<span
                                                      class="c-meta__label u-sr-only"
                                                      >${this.i18n
                                                          .salary}: </span
                                                  ><span class="c-meta__text"
                                                      >${salary.title}</span
                                                  >`
                                      ),
                                      html` / `
                                  )}
                              </li>
                          `
                        : nothing}
                    ${this.item.status && this.item.status !== 'active'
                        ? html`
                              <li
                                  class="c-meta__item c-meta__item--tag u-background-color-blue-lightest"
                              >
                                  <span class="c-meta__label u-sr-only">
                                      ${this.i18n.status}:
                                  </span>
                                  <span class="c-meta__text"> 
                                        ${this.item.status === 'waiting' 
                                            ? html`Warteliste`
                                            : nothing}
                                        ${this.item.status === 'runout'
                                            ? html`Nur noch wenige Plätze
                                                verfügbar`
                                            : nothing}
                                          ${this.item.status === 'cancelled'
                                            ? html`Abgesagt`
                                            : nothing}
                                        ${this.item.status === 'postponed' 
                                            ? html`Verschoben`
                                            : nothing}
                                        ${this.item.status === 'booked' 
                                            ? html`Ausgebucht`
                                            : nothing}
                                    </span>
                              </li>
                          `
                        : nothing}
                    ${!this.item.active && isVariantProjects
                        ? html`
                              <li
                                  class="c-meta__item c-meta__item--tag u-background-color-blue-lightest"
                              >
                                  <span class="c-meta__label u-sr-only">
                                      ${this.i18n.status}:
                                  </span>
                                  <span class="c-meta__text"
                                      >Abgeschlossen</span
                                  >
                              </li>
                          `
                        : html``}
                </ul>
            </div>
        `;
    }

    _footerMetaTemplate() {
        const isVariantJobs = this.variant === 'jobs';
        const isVariantEvents = this.variant === 'events';

        return html`
            <footer class="c-teaser__tagline c-meta">
                <ul class="c-meta__list u-color-primary">
                    ${this.item.durations
                        ? html`
                              <li class="c-meta__item">
                                  <svg
                                      class="c-icon c-icon--sm c-meta__icon"
                                      aria-hidden="true"
                                      focusable="false"
                                  >
                                      <use
                                          xlink:href="/assets/icons/sprite.svg#clock-24"
                                      />
                                  </svg>

                                  <p class="c-meta__label u-sr-only">
                                      ${this.i18n.duration ?? nothing}
                                  </p>

                                  ${join(
                                      map(
                                          this.item.durations,
                                          (duration) =>
                                              html`<span class="c-meta__text"
                                                  >${duration}</span
                                              >`
                                      ),
                                      html`/`
                                  )}
                              </li>
                          `
                        : nothing}
                    ${this.item.studylanguages
                        ? html`
                              <li class="c-meta__item">
                                  <svg
                                      class="c-icon c-icon--sm c-meta__icon"
                                      aria-hidden="true"
                                      focusable="false"
                                  >
                                      <use
                                          xlink:href="/assets/icons/sprite.svg#language-24"
                                      />
                                  </svg>

                                  <p class="c-meta__label u-sr-only">
                                      ${this.i18n.language ?? nothing}
                                  </p>

                                  ${join(
                                      map(
                                          this.item.studylanguages,
                                          (studylanguage) =>
                                              html`<span class="c-meta__text"
                                                  >${studylanguage}</span
                                              >`
                                      ),
                                      html`/`
                                  )}
                              </li>
                          `
                        : nothing}
                    ${this.item.facilities
                        ? html`
                              <li class="c-meta__item">
                                  <svg
                                      class="c-icon c-icon--sm c-meta__icon"
                                      aria-hidden="true"
                                      focusable="false"
                                  >
                                      <use
                                          xlink:href="/assets/icons/sprite.svg#job-24"
                                      />
                                  </svg>

                                  <p class="c-meta__label u-sr-only">
                                      ${this.i18n.facility ?? nothing}
                                  </p>

                                  ${join(
                                      map(
                                          this.item.facilities,
                                          (facility) =>
                                              html`<span class="c-meta__text"
                                                  >${facility.title ??
                                                  facility}</span
                                              >`
                                      ),
                                      html`/`
                                  )}
                              </li>
                          `
                        : nothing}
                    ${this.item.locations && !isVariantEvents
                        ? html`
                              <li class="c-meta__item">
                                  ${location === 'Online'
                                      ? html`<svg
                                            class="c-icon c-icon--sm c-meta__icon"
                                            aria-hidden="true"
                                            focusable="false"
                                        >
                                            <use
                                                xlink:href="/assets/icons/sprite.svg#online-24"
                                            />
                                        </svg>`
                                      : html`<svg
                                            class="c-icon c-icon--sm c-meta__icon"
                                            aria-hidden="true"
                                            focusable="false"
                                        >
                                            <use
                                                xlink:href="/assets/icons/sprite.svg#pin-24"
                                            />
                                        </svg>`}

                                  <p class="c-meta__label u-sr-only">
                                      ${this.i18n.location ?? nothing}
                                  </p>

                                  ${join(
                                      map(
                                          this.item.locations,
                                          (location) =>
                                              html`<span class="c-meta__text"
                                                  >${location.title ??
                                                  location}</span
                                              >`
                                      ),
                                      html`/`
                                  )}
                              </li>
                          `
                        : nothing}
                    ${this.item.studystarts
                        ? html`
                              <li class="c-meta__item">
                                  <svg
                                      class="c-icon c-icon--sm c-meta__icon"
                                      aria-hidden="true"
                                      focusable="false"
                                  >
                                      <use
                                          xlink:href="/assets/icons/sprite.svg#calendar-24"
                                      />
                                  </svg>

                                  <p class="c-meta__label u-sr-only">
                                      ${this.i18n.studystart ?? nothing}
                                  </p>

                                  ${join(
                                      map(
                                          this.item.studystarts,
                                          (studystart) =>
                                              html`<span class="c-meta__text"
                                                  >${studystart}</span
                                              >`
                                      ),
                                      html`/`
                                  )}
                              </li>
                          `
                        : nothing}
                </ul>
            </footer>
        `;
    }

    _headlineTemplate() {
        const isVariantPromotions = this.variant === 'promotions';
        const headlineText = `${this.item.headline || this.item.title}`;

        return html`
            <h4 class="c-teaser__heading u-text-h4">
                ${this.item.detailUrl
                    ? html`
                          <a
                              class="c-teaser__link"
                              href="${this.item.detailUrl}"
                              draggable="false"
                              ><span class="c-teaser__heading-text">
                                  ${unsafeHTML(headlineText)}</span
                              ></a
                          >
                      `
                    : html`${unsafeHTML(headlineText)}`}
            </h4>

            ${!this.item.active && isVariantPromotions
                ? html`
                      <div class="c-meta u-margin-left-4">
                          <ul class="c-meta__list u-color-primary">
                              <li
                                  class="c-meta__item c-meta__item--tag u-background-color-blue-lightest"
                              >
                                  <span class="c-meta__label u-sr-only"
                                      >Status:&nbsp;</span
                                  >
                                  <span class="c-meta__text"
                                      >Abgeschlossen</span
                                  >
                              </li>
                          </ul>
                      </div>
                  `
                : nothing}
        `;
    }

    _headerTemplate() {
        const isVariantPromotions = this.variant === 'promotions';

        const isActivePromotionHeaderClass =
            !this.item.active && isVariantPromotions
                ? ' c-teaser__header--has-label'
                : '';

        return html`
            <header class="c-teaser__header ${isActivePromotionHeaderClass}">
                ${!isVariantPromotions ? this._metaTemplate() : nothing}
                ${this._headlineTemplate()}
            </header>
        `;
    }

    _textTemplate() {
        return html`
            <div
                class="c-teaser__text c-teaser__text--clickable c-teaser__text--selectable u-color-text"
            >
                <p>
                    ${unsafeHTML(
                        limitString(this.item.teasertext, this.excerptLimit)
                    )}
                    ${unsafeHTML(
                        limitString(this.item.introtext, this.excerptLimit)
                    )}
                </p>
            </div>
        `;
    }

    _linkListTemplate() {
        const { email, phone, address } = this.item;

        return html`
            <ul
                class="c-link-list c-link-list--sm o-grid o-grid--has-gutter-y-xs u-padding-top-3"
            >
                ${email
                    ? html`
                          <li class="c-link-list__item o-grid__col--12">
                              <div class="c-link-list__item-wrapper">
                                  <a
                                      class="c-icon-link"
                                      href="mailto:${email}"
                                  >
                                      <span class="c-icon-link__icon">
                                          <svg
                                              class="c-icon"
                                              aria-hidden="true"
                                              focusable="false"
                                          >
                                              <use
                                                  xlink:href="/assets/icons/sprite.svg#envelope-24"
                                              ></use>
                                          </svg>
                                      </span>

                                      <span class="c-icon-link__content">
                                          <span
                                              class="c-icon-link__title u-text-normal"
                                              >${email}</span
                                          >
                                      </span>
                                  </a>
                              </div>
                          </li>
                      `
                    : nothing}
                ${phone
                    ? html`
                          <li class="c-link-list__item o-grid__col--12">
                              <div class="c-link-list__item-wrapper">
                                  <!-- TODO: prepend country code if missing -->
                                  <a
                                      class="c-icon-link"
                                      href="tel:${phone.replace(
                                          /[^0-9+]/g,
                                          ''
                                      ) ?? nothing}"
                                  >
                                      <span class="c-icon-link__icon">
                                          <svg
                                              class="c-icon"
                                              aria-hidden="true"
                                          >
                                              <use
                                                  xlink:href="/assets/icons/sprite.svg#phone-24"
                                              ></use>
                                          </svg>
                                      </span>

                                      <span class="c-icon-link__content">
                                          <span
                                              class="c-icon-link__title u-text-normal"
                                              >${phone}</span
                                          >
                                      </span>
                                  </a>
                              </div>
                          </li>
                      `
                    : nothing}
            </ul>
        `;
    }

    _portraitTemplate() {
        return html`
            <div class="c-teaser__media">
                <figure class="c-media">
                    <div class="c-image">
                        <picture>
                            <source
                                media="(min-width: 960px)"
                                srcset="${this.item.imagePortrait.srcSet}"
                            />
                            <source
                                media="(min-width: 480px)"
                                srcset="${this.item.imagePortrait.srcSet}"
                            />
                            <source
                                srcset="${this.item.imageLandscape.srcSet}"
                            />
                            <img
                                class="c-image__img"
                                alt="Portraitfoto von ${this.item
                                    .honorificPrefix ?? ''} ${this.item
                                    .firstname ?? ''} ${this.item.lastname ??
                                ''}"
                            />
                        </picture>
                    </div>
                </figure>
            </div>
        `;
    }

    _imageTemplate() {
        function getCredits(image) {
            if (!image.copyright) return;

            return html`
                <figcaption class="c-media__figcaption--credits-inside">
                    <span class="c-media__credits c-media__credits--inside"
                        >©&nbsp;${image.copyright}</span
                    >
                </figcaption>
            `;
        }

        return html`
            <div class="c-teaser__media">
                <figure class="c-media">
                    <div class="c-image">
                        <div
                            class="o-aspect-ratio o-aspect-ratio--${this.item
                                .image.aspectRatio ?? `1x1`}"
                        >
                            <div class="o-aspect-ratio__item">
                                <img
                                    class="c-image__img u-lazyautosizes"
                                    src="${this.item.image.src ?? nothing}"
                                    srcset="
                                        ${this.item.image.srcSet ?? nothing}
                                    "
                                    data-sizes="auto"
                                    loading="lazy"
                                    alt="${this.item.image.alternative ??
                                    nothing}"
                                />
                            </div>
                        </div>
                    </div>
                    ${getCredits(this.item.image)}
                </figure>
            </div>
        `;
    }

    _readMoreTemplate() {
        return html`
            <div
                class="c-teaser__read-more"
                aria-hidden="true"
            >
                <span class="c-button c-button--text">
                    <span class="c-button__text u-padding-right-1"
                        >${this.i18n.more ?? `Mehr`}</span
                    >
                    <svg
                        class="c-icon c-icon--sm c-button__icon"
                        aria-hidden="true"
                        focusable="false"
                    >
                        <use
                            xlink:href="/assets/icons/sprite.svg#arrow-right-24"
                        ></use>
                    </svg>
                </span>
            </div>
        `;
    }

    _authorsTemplate() {
        return html`
            <div class="c-teaser__tagline">
                <div class="c-meta c-meta--participants u-margin-top-0">
                    <ul class="c-meta__list u-color-primary">
                        <li class="c-meta__item">
                            <span class="c-meta__label"
                                >${this.item.personsListTitle ?? nothing}:</span
                            >${this.item.personsList?.map((person) => {
                                return html`<span class="c-meta__text"
                                    >${person.url
                                        ? html`<a href="${person.url}"
                                              >${person.fullname}</a
                                          >`
                                        : html`${person.fullname}`}</span
                                >`;
                            })}
                        </li>
                    </ul>
                </div>
            </div>
        `;
    }

    _abstractTemplate() {
        return html`
            <div
                class="c-teaser__text c-teaser__text--clickable c-teaser__text--selectable u-color-text"
            >
                <details class="c-details">
                    <summary class="c-details__summary">
                        <span
                            class="c-details__summary-text c-button c-button--text"
                        >
                            Abstract
                        </span>
                    </summary>
                    <div class="c-details__body">
                        <p>${this.item.abstract}</p>
                    </div>
                </details>
            </div>
        `;
    }

    _authorsPublicationTemplate() {
        function getToggleText(expanded) {
            return expanded ? 'Weniger Autoren' : 'Mehr Autoren';
        }

        function getToggleIcon(expanded) {
            return expanded ? 'chevron-up-24' : 'chevron-down-24';
        }

        return html`
            <div class="c-teaser__authormeta u-margin-bottom-2">
                <div class="c-teaser__authors">
                    ${this.isAuthorMetaOverflowing || this.isAuthorMetaExpanded
                        ? html`
                              <button
                                  class="c-button c-button--text c-teaser__authors-toggle"
                                  type="button"
                                  aria-expanded="false"
                                  @click=${this._onExpandClick}
                              >
                                  <span class="c-button__text u-padding-right-1"
                                      >${getToggleText(
                                          this.isAuthorMetaExpanded
                                      )}</span
                                  >
                                  <svg
                                      class="c-icon c-icon--sm c-button__icon"
                                      aria-hidden="true"
                                      focusable="false"
                                  >
                                      <use
                                          href="/assets/icons/sprite.svg#${getToggleIcon(
                                              this.isAuthorMetaExpanded
                                          )}"
                                      ></use>
                                  </svg>
                              </button>
                          `
                        : nothing}

                    <div class="c-teaser__authors-text js-overflow-check">
                        <p>
                            ${join(
                                map(
                                    this.item.personsList,
                                    (person) =>
                                        html`<span
                                            >${person.url
                                                ? html`<a href="${person.url}"
                                                      >${person.fullname}</a
                                                  >`
                                                : html`${person.fullname}`}</span
                                        >`
                                ),
                                html`, `
                            )}
                        </p>
                    </div>
                </div>

                ${this.item.openAccess || this.item.peerReviewed
                    ? html`
                          <div
                              class="c-meta c-meta--publication-tags u-margin-left-4"
                          >
                              <ul class="c-meta__list u-color-primary">
                                  ${this.item.openAccess
                                      ? html` <li
                                            class="c-meta__item c-meta__item--tag u-background-color-blue-lightest"
                                        >
                                            <span
                                                class="c-meta__label u-sr-only"
                                                >Berechtigungen:&nbsp;</span
                                            >
                                            <span class="c-meta__text"
                                                >Open Access</span
                                            >
                                        </li>`
                                      : html``}
                                  ${this.item.peerReviewed
                                      ? html`
                                            <li
                                                class="c-meta__item c-meta__item--tag u-background-color-blue-lightest"
                                            >
                                                <span
                                                    class="c-meta__label u-sr-only"
                                                    >Peer review:&nbsp;</span
                                                >
                                                <span class="c-meta__text"
                                                    >Peer reviewed</span
                                                >
                                            </li>
                                        `
                                      : html``}
                              </ul>
                          </div>
                      `
                    : nothing}
            </div>
        `;
    }

    render() {
        const {
            abstract,
            active,
            address,
            categories,
            degree,
            detailUrl,
            doctoralCandidate,
            durations,
            email,
            endDateList,
            endTimeList,
            facilities,
            firstname,
            fullname,
            headline,
            honorificPrefix,
            introtext,
            lastname,
            locations,
            personsList,
            phone,
            startDateList,
            startTimeList,
            studylanguages,
            studystarts,
            studytypes,
            supervisorExternal,
            supervisorExternalPartner,
            supervisorInternal,
            teasertext,
            title,
        } = this.item;

        const isPromotion =
            doctoralCandidate ||
            supervisorExternal ||
            supervisorExternalPartner ||
            supervisorInternal;

        const isVariantJobs = this.variant === 'jobs';
        const isVariantEvents = this.variant === 'events';
        const isVariantNews = this.variant === 'newsitems';
        const isVariantPeople = this.variant === 'people';
        const isVariantProjects = this.variant === 'projects';
        const isVariantPromotions = this.variant === 'promotions';
        const isVariantPublications = this.variant === 'publications';

        if (isVariantPeople) {
            //
            const getHeadingText = () => {
                if (this.isFilteredByLastName) {
                    return html`${lastname}${firstname
                        ? `, ${firstname}`
                        : nothing}${honorificPrefix
                        ? `, ${honorificPrefix}`
                        : nothing}`;
                }
                return html`${honorificPrefix} ${firstname} ${lastname}`;
            };

            return html`
                <article class="c-teaser c-teaser--list-persons">
                    <!-- portrait -->

                    ${isVariantPeople ? this._portraitTemplate() : nothing}

                    <!-- body -->

                    <div class="c-teaser__body">
                        <!-- header -->

                        <header class="c-teaser__header">
                            <h4 class="c-teaser__heading u-text-h5">
                                <span class="c-teaser__heading-text"
                                    >${getHeadingText()}</span
                                >
                            </h4>
                        </header>

                        ${this.item.position
                            ? html`<div class="c-teaser__text">
                                  <p>${this.item.position}</p>
                              </div>`
                            : nothing}

                        <!-- link list -->

                        ${email || phone || address
                            ? this._linkListTemplate()
                            : nothing}

                        <!-- detail link -->

                        ${this.item.detailUrl
                            ? html`
                                  <div
                                      class="c-teaser__read-more"
                                      aria-hidden="true"
                                  >
                                      <a
                                          href="${this.item.detailUrl}"
                                          class="c-button c-button--text"
                                      >
                                          <span
                                              class="c-button__text u-padding-right-1"
                                              >${this.i18n.more ??
                                              `Zum Profil`}</span
                                          >
                                          <svg
                                              class="c-icon c-icon--sm c-button__icon"
                                              aria-hidden="true"
                                              focusable="false"
                                          >
                                              <use
                                                  xlink:href="/assets/icons/sprite.svg#arrow-right-24"
                                              ></use>
                                          </svg>
                                      </a>
                                  </div>
                              `
                            : nothing}
                    </div>
                </article>
            `;
        }

        if (isVariantPublications) {
            return html`
                <article
                    class="c-teaser c-teaser--list-left ${this.item.detailUrl
                        ? html` c-teaser--has-link js-teaser--has-link`
                        : html``}"
                >
                    <!-- body -->

                    <div class="c-teaser__body">
                        ${personsList?.length
                            ? this._authorsPublicationTemplate()
                            : nothing}

                        <header class="c-teaser__header">
                            <h4
                                class="c-teaser__heading u-text-md u-text-normal"
                            >
                                ${this.item.detailUrl
                                    ? html`
                                          <a
                                              class="c-teaser__link"
                                              href="${this.item.detailUrl}"
                                              draggable="false"
                                              ><span
                                                  class="c-teaser__heading-text"
                                              >
                                                  <strong>${title}</strong>
                                                  (${this.item.year})
                                                  ${this.item.media ||
                                                  this.item.value ||
                                                  this.item.issue ||
                                                  this.item.pages
                                                      ? html` ${this.item.media}
                                                        ${this.item.volume}
                                                        ${this.item.issue
                                                            ? html`(${this.item
                                                                  .issue})`
                                                            : html``}
                                                        ${this.item.pages
                                                            ? html`, S.
                                                              ${this.item.pages}`
                                                            : html``}
                                                        ${this.item.doi
                                                            ? html`. `
                                                            : html``}`
                                                      : html``}
                                                  ${this.item.doi
                                                      ? html`DOI:
                                                        ${this.item.doi}`
                                                      : html``}</span
                                              ></a
                                          >
                                      `
                                    : html`<strong>${title}</strong> (${this
                                              .item.year})
                                          ${this.item.media ||
                                          this.item.value ||
                                          this.item.issue ||
                                          this.item.pages
                                              ? html` ${this.item.media}
                                                ${this.item.volume}
                                                ${this.item.issue
                                                    ? html`(${this.item.issue})`
                                                    : html``}
                                                ${this.item.pages
                                                    ? html`, S.
                                                      ${this.item.pages}`
                                                    : html``}
                                                ${this.item.doi
                                                    ? html`. `
                                                    : html``}`
                                              : html``}
                                          ${this.item.doi
                                              ? html`DOI: ${this.item.doi}`
                                              : html``}`}
                            </h4>
                        </header>

                        ${abstract ? this._abstractTemplate() : nothing}

                        <!-- read-more -->

                        ${detailUrl ? this._readMoreTemplate() : nothing}
                    </div>
                </article>
            `;
        }

        if (!isVariantPeople && !isVariantPublications) {
            return html`
                <article
                    class="c-teaser c-teaser--list-left ${detailUrl
                        ? html` c-teaser--has-link js-teaser--has-link`
                        : html``}"
                >
                    <!-- image -->

                    ${this.item.image
                        ? this._imageTemplate({ ...this.item.image })
                        : nothing}

                    <!-- body -->

                    <div class="c-teaser__body">
                        <!-- header -->

                        ${headline || title || degree || fullname
                            ? this._headerTemplate()
                            : nothing}

                        <!-- facts list (promotions) -->

                        ${isPromotion ? this._factsListTemplate() : nothing}

                        <!-- excerpt -->

                        ${introtext || teasertext
                            ? this._textTemplate()
                            : nothing}

                        <!-- read-more -->

                        ${detailUrl ? this._readMoreTemplate() : nothing}
                    </div>

                    <!-- authors -->
                    ${personsList?.length ? this._authorsTemplate() : nothing}

                    <!-- footer-meta -->
                    ${!isVariantEvents &&
                    (durations ||
                        locations ||
                        studylanguages ||
                        studystarts ||
                        studytypes)
                        ? this._footerMetaTemplate(this.item)
                        : nothing}
                </article>
            `;
        }
    }
}

customElements.define('lf-list-item', LfListItem);

// {
//       "fullname": "Markus Abel",
//       "email": "markus.abel@hswt.de",
//       "phone": "+49 8161 71-2410",
//       "firstname": "Markus",
//       "lastname": "Abel",
//       "title": "",
//       "address": "Am Staudengarten 10\r\n85354 Freising",
//       "facilities": [
//         {
//           "uid": 26,
//           "title": "Fakultät Gartenbau und Lebensmitteltechnologie"
//         },
//         {
//           "uid": 44,
//           "title": "Institut für Gartenbau"
//         }
//       ],
//       "locations": [
//         {
//           "uid": 1,
//           "title": "Campus Weihenstephan"
//         }
//       ],
//       "statusgroup": [
//         {
//           "uid": 5,
//           "title": "wissenschaftliches Personal"
//         }
//       ]
//     },

// "i18n": {
//     "status": "Status",
//     "closed": "Abgeschlossen",
//     "doctoralCandidate": "Promovierende Person",
//     "supervisorInternal": "Wissenschaftlich betreuende Person",
//     "facility": "Einrichtung",
//     "supervisorExternal": "Wissenschaftlich betreuende Person (extern)",
//     "duration": "Zeitraum",
//     "yourSearch": "Ergebnisse %s bis %s von ",
//     "noResultsHeadline": "Keine Ergebnisse!",
//     "noResultsMessage": "Leider konnten keine Ergebnisse zu Deiner Suchanfrage gefunden werden. Bitte versuche es erneut mit einer veränderten Suchanfrage."
//   },
