/**
 * Check for session storage and that it actually works
 */

const supportsStorage = function () {
    var testString = 'this is the test string';
    var result;
    try {
        window.sessionStorage.setItem(testString, testString);
        result =
            window.sessionStorage.getItem(testString) === testString.toString();
        window.sessionStorage.removeItem(testString);
        return result;
    } catch (exception) {
        if (
            typeof console === 'undefined' ||
            typeof console.log === 'undefined'
        ) {
            console.log('Notice: sessionStorage not available.');
        }
    }
};

export default supportsStorage;
