export default function showOnIntersect(
    fabSelector = '.js-fab-show-on-intersect',
    targetSelector = '.c-breadcrumb, .c-teaser-stage'
) {
    const buttonElement = document.querySelector(fabSelector);
    const targetElement = document.querySelector(targetSelector);

    // Sanity check
    if (!targetElement || !buttonElement) return;

    // Consider the viewport to be 200% larger than it actually is
    // so the top link shows half a screen *before* the footer becomes
    // visible in the viewport.
    const observerOptions = {
        rootMargin: '50% 0% 0% 0%',
    };

    // Create a callback that will be called
    // when the target element is intersected.
    function handleIntersection(entries) {
        entries.forEach((entry) => {
            if (!entry.isIntersecting) {
                buttonElement.classList.add('is-shown');
            } else {
                buttonElement.classList.remove('is-shown');
            }
        });
    }

    // Instantiate the observer
    let observer = new IntersectionObserver(
        handleIntersection,
        observerOptions
    );

    // Start observing the target
    observer.observe(targetElement);
}
