import { html, nothing } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';

class LfAbcPagination extends BaseCustomElement {
    static get properties() {
        return {
            letters: { type: Object },
            isLoading: { type: Boolean },
        };
    }

    constructor() {
        super();
        this.letters = {
            candidates: [],
            currentLetter: '',
        };
        this._currentLetter = '';
        this.i18n = {
            all: 'Alle',
        };

        this._onApiRequest = this._onApiRequest.bind(this);
        this._onApiResponse = this._onApiResponse.bind(this);
        this._onFilterFormChange = this._onFilterFormChange.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('api-request', this._onApiRequest);
        window.addEventListener('api-response', this._onApiResponse);
        window.addEventListener('filter-form-change', this._onFilterFormChange);
    }

    disConnectedCallback() {
        super.disConnectedCallback();
        window.removeEventListener('api-request', this._onApiRequest);
        window.removeEventListener('api-response', this._onApiResponse);
        window.removeEventListener(
            'filter-form-change',
            this._onFilterFormChange
        );
    }

    _removeSsrChildNodes() {
        while (this.firstChild) {
            this.removeChild(this.firstChild);
        }
    }

    _dispatchInitialLetterChange() {
        const event = new CustomEvent('initial-letter-change', {
            bubbles: true,
            detail: {
                letter: this._currentLetter,
            },
        });
        this.dispatchEvent(event);
    }

    _onClick(event) {
        const clickedLetter = event.target.dataset.letter;
        if (this._currentLetter !== clickedLetter) {
            this._currentLetter = clickedLetter;
            this._dispatchInitialLetterChange();
        }
    }

    _onApiRequest(event) {
        this.isLoading = true;
    }

    _onApiResponse(event) {
        this.isLoading = false;
        this.letters = event.detail.filters?.letters ?? {};
    }

    _onFilterFormChange(event) {
        if (event.detail.isReset) {
            this._currentLetter = '';
        }
    }

    willUpdate(changedProperties) {
        if (changedProperties.has('letters')) {
            this._currentLetter = this.letters.currentLetter;
        }
    }

    render() {
        if (!this.letters?.candidates?.length) {
            return;
        }

        return html`
            <div class="c-pagination c-pagination--abc">
                <ol class="c-pagination__list o-grid o-grid--gutter-xs">
                    <li class="o-grid__col--auto c-pagination__item">
                        <button
                            class="c-button c-button--square"
                            data-letter=""
                            @click=${this._onClick}
                        >
                            <span class="c-button__text">${this.i18n.all}</span>
                        </button>
                    </li>

                    ${this.letters?.candidates?.map((letter) => {
                        return html`
                            <li class="o-grid__col--auto c-pagination__item">
                                <button
                                    class="c-button c-button--square ${this
                                        ._currentLetter === letter
                                        ? `is-current`
                                        : ''}"
                                    data-letter="${letter}"
                                    @click=${this._onClick}
                                >
                                    <span class="c-button__text"
                                        >${letter}</span
                                    >
                                </button>
                            </li>
                        `;
                    })}
                </ol>
            </div>
        `;
    }
}

customElements.define('lf-abc-pagination', LfAbcPagination);
