import supportsStorage from '../../../scripts/utils/supports-storage';

function Toggle($module) {
    this.$module = $module;
    this.themeName = $module.getAttribute('data-toggles');
    this.supportsStorage = supportsStorage();
    this.isChecked = 'false';
    this.storageKey = 'COLOR_SCHEME';
    this.isSysPrefDark = window.matchMedia('(prefers-color-scheme: dark)');
    this.isSysPrefLight = window.matchMedia('(prefers-color-scheme: light)');
    this.isSysPrefHighContrast = window.matchMedia('(prefers-contrast: more)');
    this.isSysPrefReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)');
}

/**
 * Set up the toggle button
 */
Toggle.prototype.init = function () {
    if (!this.$module) {
        return;
    }

    this.$module.setAttribute('role', 'switch');

    this.getInitialState();
    this.setInitialState();

    this.$module.boundOnClick = this.handleClick.bind(this);
    this.$module.addEventListener('click', this.$module.boundOnClick, false);
};

/**
 * Get initial toggle state from session storage
 */
Toggle.prototype.getInitialState = function () {
    if (this.supportsStorage) {
        let storedState = window.sessionStorage.getItem(this.storageKey);

        if (this.themeName === 'dark') {
            if (storedState === 'light') this.isChecked = true;
            if (storedState === 'dark') this.isChecked = true;
        } else {
            this.isChecked = storedState === this.themeName;
        }
    }

    return this.isChecked;
};

/**
 * Set initial toggle state and save to session storage
 */
Toggle.prototype.setInitialState = function () {
    const root = document.documentElement;
    const store = window.sessionStorage;
    let storedState = window.sessionStorage.getItem(this.storageKey);

    if (this.themeName === 'dark' && this.isSysPrefDark.matches) {
        root.setAttribute('data-user-color-scheme', 'dark');
        this.$module.setAttribute('data-state', 'dark');
        store.setItem(this.storageKey, 'dark');
    }

    if (this.isChecked) {
        // root.setAttribute('data-user-color-scheme', this.themeName);
        if (this.themeName === 'dark') {
            if (storedState === 'light') {
                root.setAttribute('data-user-color-scheme', 'light');
                this.$module.setAttribute('data-state', 'light');
                store.setItem(this.storageKey, 'light');
            } else if (storedState === 'dark') {
                root.setAttribute('data-user-color-scheme', 'dark');
                this.$module.setAttribute('data-state', 'dark');
                store.setItem(this.storageKey, 'dark');
            }
        } else {
            root.setAttribute('data-user-color-scheme', this.themeName);
            store.setItem(this.storageKey, this.themeName);
        }
    }

    this.$module.setAttribute('aria-checked', this.isChecked);
};

/**
 * Change and save toggle state on click
 */
Toggle.prototype.handleClick = function () {
    const root = document.documentElement;
    const store = window.sessionStorage;
    let storedState = window.sessionStorage.getItem(this.storageKey);

    let isChecked = this.$module.getAttribute('aria-checked') === 'true';

    const $toggles = document.querySelectorAll('.js-toggle');

    for (const thisToggle of $toggles) {
        thisToggle.removeAttribute('aria-checked');
    }

    if (isChecked) {
        /*
        root.removeAttribute('data-user-color-scheme');

        // this.$module.removeAttribute('aria-checked');
        store.removeItem(this.storageKey, this.themeName);
        */

        if (this.themeName === 'dark') {
            if (root.getAttribute('data-user-color-scheme') === 'dark') {
                root.setAttribute('data-user-color-scheme', 'light');
                this.$module.setAttribute('data-state', 'light');
                store.setItem(this.storageKey, 'light');
            } else {
                root.setAttribute('data-user-color-scheme', 'dark');
                this.$module.setAttribute('data-state', 'dark');
                store.setItem(this.storageKey, 'dark');
            }
        } else {
            if (storedState === 'high-contrast') {
                root.setAttribute(
                    'data-user-color-scheme',

                    // document.getElementById('toggle-dark').getAttribute('data-state')
                    document
                        .querySelector('[data-toggles="dark"]')
                        .getAttribute('data-state')
                );
                store.setItem(
                    this.storageKey,

                    document
                        .querySelector('[data-toggles="dark"]')
                        .getAttribute('data-state')
                );

                const $toggleDarkButtons = document.querySelectorAll(
                    '[data-toggles="dark"]'
                );
                for (const $toggleDarkButton of $toggleDarkButtons) {
                    $toggleDarkButton.setAttribute('aria-checked', 'true');
                }

                this.$module.setAttribute('aria-checked', 'false');
            } else {
                root.setAttribute('data-user-color-scheme', this.themeName);
                store.setItem(this.storageKey, this.themeName);
            }
        }
    } else {
        root.setAttribute('data-user-color-scheme', this.themeName);
        this.$module.setAttribute('aria-checked', 'true');
        store.setItem(this.storageKey, this.themeName);
    }

    this.isChecked = !this.isChecked;
};

export default Toggle;
