/**
 * @file Global LazySizes config
 */

import lazySizes from 'lazysizes';

import 'lazysizes/plugins/custommedia/ls.custommedia';
import 'lazysizes/plugins/print/ls.print';
import 'lazysizes/plugins/respimg/ls.respimg';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

// TODO: no, it’s not. We should reverse this change.
// Prefix default class names with `u-` as these are utility classes
// i.e. instead of `lazyload` use `u-lazyload`

lazySizes.cfg.autosizesClass = 'u-lazyautosizes';
lazySizes.cfg.errorClass = 'u-lazyerror';
lazySizes.cfg.fastLoadedClass = 'u-ls-is-cached';
lazySizes.cfg.lazyClass = 'u-lazyload';
lazySizes.cfg.loadedClass = 'u-lazyloaded';
lazySizes.cfg.loadingClass = 'u-lazyloading';
lazySizes.cfg.preloadClass = 'u-lazypreload';

// Sync breakpoints between CSS and the media attributes
lazySizes.cfg.customMedia;

export default lazySizes;
