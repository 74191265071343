/* eslint-disable no-console */

import { html, nothing } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';
import '../abc-pagination/abc-pagination.js';
import '../button/button.js';
import '../filter-facets/filter-facets.js';
import '../filter-form/filter-form.js';
import '../filter-status/filter-status.js';
import '../list/list.js';
import '../pagination/pagination.js';
import '../toolbar/toolbar.js';

class LfFilterResult extends BaseCustomElement {
    static get properties() {
        return {
            data: { type: Object },
            hasError: { type: Boolean },
            isFirstRun: { type: Boolean },
            isLoading: { type: Boolean },
            isSmallViewport: { type: Boolean },
            showFacets: { type: Boolean },
            filterFormId: {
                type: String,
                attribute: 'filter',
            },
            apiEndpoint: {
                type: String,
                attribute: 'endpoint',
            },
            infiniteOnly: {
                type: Boolean,
                attribute: 'infinite-only',
            },
        };
    }

    constructor() {
        super();
        this._currentLetter = '';
        this._currentPage = 1;
        this._items = [];
        this._letters = {};
        this.$filterForm = '';
        this.apiEndpoint = '';
        this.apiMethod = 'post';
        this.appendPages = false;
        this.breakPoint = '960px';
        this.data = {
            filters: {},
            variant: '',
            i18n: {},
            items: [],
            itemsCount: 0,
            locale: 'de',
            pagination: {
                currentPage: 1,
                numberOfEntries: 0,
                metainformation: {},
                pageLinks: [],
                navigationLinks: {
                    prepend: [],
                    append: [],
                },
            },
        };
        this.filterFormId = '';
        this.formData = {};
        this.hasError = false;
        this.hasSsrNodesRemoved = false;
        this.infiniteOnly = false;
        this.hasInitialLetterChanged = false;
        this.isLoading = undefined;
        this.isFirstRun = true;
        this.isSmallViewport = false;
        this.locale = 'de';
        this.mediaQuery = null;
        this.showFacets = true;

        this._bindEventListeners();
    }

    // ------------------------------------------------------------------------

    _makeApiRequest() {
        if (this.isLoading) return;

        let payLoad = this.formData;
        payLoad.set('page', this._currentPage);

        // TODO: generalize this for all potential FormData additions
        if (this.apiEndpoint === '/api/people') {
            payLoad.set(
                'tx_lfaddress_list[filter][abc]',
                this._currentLetter ?? ''
            );
        }

        this.isLoading = true;
        this._dispatchApiRequest();

        console.log('========================================');
        console.log('REQUEST: ', [...this.formData]);

        return (
            fetch(this.apiEndpoint, {
                method: this.apiMethod,
                body: payLoad,
                headers: {
                    'Accept-Language': this.locale,
                },
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('API response not OK: ', response);
                    }
                    return response.json();
                })
                .then((response) => this._onApiRequestSuccess(response))
                // .catch((error) => this._onApiRequestError(error))
                .catch((error) => {
                    this.hasError = true;
                    if (typeof error.json === 'function') {
                        error
                            .json()
                            .then((jsonError) => {
                                console.log('Json error from API');
                                console.log(jsonError);
                            })
                            .catch((genericError) => {
                                console.log('Generic error from API');
                                console.log(error.statusText);
                            });
                    } else {
                        console.log('Fetch error');
                        console.log(error);
                    }
                })
                .finally(() => {
                    this.isLoading = false;
                    this.isFirstRun = false;
                })
        );
    }

    _onApiRequestSuccess(ResponseData) {
        console.log('----------------------------------------');
        console.log('RESPONSE: ', ResponseData);

        this.data = ResponseData;
        this._currentPage = ResponseData.pagination?.currentPage ?? '1';
        this._currentLetter =
            ResponseData.filters?.letters?.currentLetter ?? '';
        this.hasError = false;
        this.isLoading = false;

        this._dispatchApiResponse();
    }

    _onApiRequestError(error) {
        throw new Error('API error: ', error);
        this.isLoading = false;
        this.hasError = true;
    }

    _removeSsrNodes() {
        const ssrNodes = document.querySelectorAll('.hide-on-enhance');
        ssrNodes?.forEach((node) => {
            node.remove();
        });
        this.hasSsrNodesRemoved = true;
    }

    /**
     * Change to or append the new page.
     */
    _getItems() {
        // Never append results of a new query
        if (this.hasFilterChanged) {
            return this.data.items;
        }

        return this.appendPages
            ? [...this._items, ...this.data.items]
            : this.data.items;
    }

    _bindEventListeners() {
        this._onFilterFormChange = this._onFilterFormChange.bind(this);
        this._onFilterFormReset = this._onFilterFormReset.bind(this);
        this._onInitialLetterChange = this._onInitialLetterChange.bind(this);
        this._onPageAppend = this._onPageAppend.bind(this);
        this._onPageChange = this._onPageChange.bind(this);
    }

    _addEventListeners() {
        this.mediaQuery.addEventListener('change', this._onMediaQueryChange);
        window.addEventListener('filter-form-change', this._onFilterFormChange);
        window.addEventListener('filter-form-reset', this._onFilterFormReset);
        window.addEventListener(
            'initial-letter-change',
            this._onInitialLetterChange
        );
        window.addEventListener('page-append', this._onPageAppend);
        window.addEventListener('page-change', this._onPageChange);
    }

    _removeEventListeners() {
        this.mediaQuery.removeEventListener('change', this._onMediaQueryChange);
        window.removeEventListener(
            'filter-form-change',
            this._onFilterFormChange
        );
        window.removeEventListener(
            'initial-letter-change',
            this._onInitialLetterChange
        );
        window.removeEventListener('page-append', this._onPageAppend);
        window.removeEventListener('page-change', this._onPageChange);
    }

    // Events
    // ------------------------------------------------------------------------

    _dispatchApiRequest() {
        const event = new CustomEvent('api-request', {
            bubbles: true,
            detail: {
                data: this.data,
                filters: this.data?.filters,
                hasError: this.hasError,
                hasFilterChanged: this.hasFilterChanged,
                hasInitialLetterChanged: this.hasInitialLetterChanged,
                isLoading: this.isLoading,
                isFirstRun: this.isFirstRun,
                isUserInitiated: this.isUserInitiated,
                numberOfEntries: this.data?.pagination?.numberOfEntries,
            },
        });
        this.dispatchEvent(event);
    }

    _dispatchApiResponse() {
        const event = new CustomEvent('api-response', {
            bubbles: true,
            detail: {
                data: this.data,
                filters: this.data?.filters,
                hasError: this.hasError,
                hasFilterChanged: this.hasFilterChanged,
                hasInitialLetterChanged: this.hasInitialLetterChanged,
                isLoading: this.isLoading,
                isFirstRun: this.isFirstRun,
                isUserInitiated: this.isUserInitiated,
                numberOfEntries: this.data?.pagination?.numberOfEntries,
            },
        });
        this.dispatchEvent(event);
    }

    // Event Handlers
    // -----------------------------------------------------------------------

    _onMediaQueryChange(event) {
        const isSmallViewport = event.matches;
        this.isSmallViewport = isSmallViewport;
        this.appendPages = isSmallViewport;
        this.showFacets = !isSmallViewport;
    }

    _onFilterFormChange(event) {
        this.formData = event.detail.formData;
        this._currentPage = 1;
        this.hasFilterChanged = true;
        this.hasInitialLetterChanged = false;
        this._makeApiRequest();
    }

    _onFilterFormReset(event) {
        this.formData = event.detail.formData;
        this._currentPage = 1;
        this.hasFilterChanged = false;
        this.hasInitialLetterChanged = false;
        this._makeApiRequest();
    }

    _onInitialLetterChange(event) {
        this._currentLetter = event.detail.letter;
        this._currentPage = 1;
        this.hasFilterChanged = false;
        this.hasInitialLetterChanged = true;
        this._makeApiRequest();
        this.scrollIntoView(true);
    }

    _onPageAppend(event) {
        this._currentPage = event.detail.newPage;
        this.appendPages = true;
        this.hasFilterChanged = false;
        this.hasInitialLetterChanged = false;
        this._makeApiRequest();
    }

    _onPageChange(event) {
        this._currentPage = event.detail.newPage;
        this.hasFilterChanged = false;
        this.hasInitialLetterChanged = false;
        this._makeApiRequest();
        this.scrollIntoView(true);
    }

    // Lifecycle
    // ------------------------------------------------------------------------

    connectedCallback() {
        super.connectedCallback();
        // this.isFirstRun = true;
        this.$filterForm = document.forms[this.filterFormId];
        this.formData = new FormData(this.$filterForm);
        this.mediaQuery = window.matchMedia(`(max-width: ${this.breakPoint})`);
        this.isSmallViewport = this.mediaQuery.matches;
        this._addEventListeners();
    }

    /**
     * Clean up after element is removed from the DOM.
     * @category — lifecycle
     */
    disconnectedCallback() {
        super.disconnectedCallback();
        this._removeEventListeners();
    }

    /**
     * Perform one time work after the first update.
     * @category — updates
     * @param _changedProperties – Map of changed properties with old values.
     */
    firstUpdated(_changedProperties) {
        this._makeApiRequest();
        // Make this focusable, so we can scroll to the start of the list after page change.
        this.tabIndex = 0;
    }

    willUpdate(_changedProperties) {
        if (_changedProperties.has('data')) {
            this._items = this._getItems();
            this._letters = this.data.filters?.letters ?? {};

            if (this.isFirstRun && this.data.items?.length > 0) {
                this._removeSsrNodes();
            }
        }
    }

    render() {
        return html`
            <lf-toolbar
                ?hasError=${this.hasError}
                ?isFirstRun=${this.isFirstRun}
                ?isLoading=${this.isLoading}
                ?isSmallViewport=${this.isSmallViewport}
                ?isUserInitiated=${this.isUserInitiated}
                .filterFormId=${this.filterFormId}
                .formData=${this.formData}
                .i18n=${this.data.i18n}
                .itemsCount=${this.itemsCount}
                .locale=${this.locale}
                .pagination=${this.data.pagination}
            >
            </lf-toolbar>

            <lf-list
                class="c-teaser-set o-grid o-grid--has-gutter-none u-margin-bottom-5"
                role="list"
                ?hasError=${this.hasError}
                ?isFirstRun=${this.isFirstRun}
                ?isLoading=${this.isLoading}
                ?isUserInitiated=${this.isUserInitiated}
                ?isFilteredByLastName="false"
                ?isSmallViewport=${this.isSmallViewport}
                .i18n=${this.data.i18n}
                .variant=${this.data.variant}
                .items=${this._items}
            ></lf-list>

            <lf-pagination
                class="u-margin-top-0 u-margin-bottom-10"
                ?hasError=${this.hasError}
                ?isFirstRun=${this.isFirstRun}
                ?isLoading=${this.isLoading}
                ?isSmallViewport=${this.isSmallViewport}
                ?infinite-only=${this.infiniteOnly}
                .i18n=${this.data.i18n}
                .itemsCount=${this.data.itemsCount}
                .page=${this._currentPage}
                .pagination=${this.data.pagination}
            ></lf-pagination>
        `;
    }
}

customElements.define('lf-filter-result', LfFilterResult);
