/**
 * A list of CSS selectors for all (tab-) focusable HTML elements.
 * @see https://allyjs.io/data-tables/focusable.html
 * @const {Array.<String>}
 */
const FOCUSABLE_SELECTORS = [
    'a[href]:not([tabindex^="-"])',
    'area[href]:not([tabindex^="-"])',
    'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
    'input[type="radio"]:not([disabled]):not([tabindex^="-"])',
    'select:not([disabled]):not([tabindex^="-"])',
    'textarea:not([disabled]):not([tabindex^="-"])',
    'button:not([disabled]):not([tabindex^="-"])',
    'iframe:not([tabindex^="-"])',
    'audio[controls]:not([tabindex^="-"])',
    'video[controls]:not([tabindex^="-"])',
    '[contenteditable]:not([tabindex^="-"])',
    '[tabindex]:not([tabindex^="-"])',
];

/**
 * Check whether a node is visible or not by checking its dimensions.
 * @param {Node} node - The node to check.
 * @returns {Boolean} Whether the given node is visible.
 */
const isVisible = (node) => {
    return !!(
        node.offsetWidth ||
        node.offsetHeight ||
        node.getClientRects().length
    );
};

/**
 * Get all focusable children of an element.
 * @param {HTMLElement} parentNode [document]
 * @returns {Array.<HTMLElement>}
 */
const getFocusableChildren = (parentNode = document) => {
    return [
        ...parentNode.querySelectorAll(FOCUSABLE_SELECTORS.join(',')),
    ].filter(isVisible);
};

/**
 * Trap (tab-) focus inside the given element.
 * @param {HTMLElement} parentNode
 * @param {KeyboardEvent} event
 */
function trapFocus(parentNode, event) {
    const focusableChildren = getFocusableChildren(parentNode);
    const focusedItemIndex = focusableChildren.indexOf(document.activeElement);

    if (event.shiftKey && focusedItemIndex === 0) {
        // If the SHIFT key is pressed while tabbing (moving backwards) and the
        // currently focused item is the first one, move the focus to the last
        // focusable item from the dialog element

        focusableChildren[focusableChildren.length - 1].focus();
        event.preventDefault();
    } else if (
        // If the SHIFT key is not pressed (moving forwards) and the currently
        // focused item is the last one, move the focus to the first focusable item
        // from the dialog element

        !event.shiftKey &&
        focusedItemIndex === focusableChildren.length - 1
    ) {
        focusableChildren[0].focus();
        event.preventDefault();
    }
}

export default trapFocus;
