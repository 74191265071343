import { html, svg, nothing } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';

class LfPagination extends BaseCustomElement {
    static get properties() {
        return {
            pagination: { type: Object },
            hasError: { type: Boolean },
            isLoading: { type: Boolean },
            isSmallViewport: { type: Boolean },
            infiniteOnly: {
                type: Boolean,
                attribute: 'infinite-only',
            },
        };
    }

    constructor() {
        super();
        this.hasError = undefined;
        this.i18n = {};
        this.isLoading = undefined;
        this.isSmallViewport = false;
        this.infiniteOnly = undefined;
        this.itemsCount = 0;
        this.pagination = {
            currentPage: 1,
            pagesCount: 1,
            metainformation: {},
            navigationLinks: {
                prepend: [],
                append: [],
            },
            numberOfEntries: 0,
            pageLinks: [],
        };
        this._currentPage = 1;
    }

    _onPageClick(event) {
        const requestedPage = event.target.getAttribute('data-page');
        if (this._currentPage !== requestedPage) {
            this._currentPage = requestedPage;
            this._dispatchPageChange();
        }
    }

    _onLoadMoreClick(event) {
        const requestedPage = this._currentPage + 1;
        this._currentPage = requestedPage;
        this._dispatchPageAppend();
    }

    _dispatchPageAppend() {
        const event = new CustomEvent('page-append', {
            bubbles: true,
            detail: {
                newPage: this._currentPage,
            },
        });
        this.dispatchEvent(event);
    }

    _dispatchPageChange() {
        const event = new CustomEvent('page-change', {
            bubbles: true,
            detail: {
                newPage: this._currentPage,
            },
        });
        this.dispatchEvent(event);
    }

    _getIconId(rel) {
        if (rel === 'first') {
            return 'chevron-left-double-24';
        } else if (rel === 'previous') {
            return 'chevron-left-24';
        } else if (rel === 'next') {
            return 'chevron-right-24';
        } else if (rel === 'last') {
            return 'chevron-right-double-24';
        } else {
            return;
        }
    }

    _iconTemplate(rel) {
        return svg`
            <svg
                class="c-icon c-icon--sm"
                aria-hidden="true"
            >
                <use href="/assets/icons/sprite.svg#${this._getIconId(rel)}" />
            </svg>
        `;
    }

    // -----------------------------------------------------------------

    _navigationItemTemplate(item) {
        return html`
            <li class="o-grid__col--auto">
                <button
                    class="c-button c-button--square"
                    rel="${item.type}"
                    data-page="${item.pagenum}"
                    data-href="${item.link}"
                    @click="${this._onPageClick}"
                >
                    ${this._iconTemplate(item.type)}
                    <span class="c-button__text u-sr-only">${item.type}</span>
                </button>
            </li>
        `;
    }

    _pageItemTemplate(item) {
        return html`
            <li class="o-grid__col--auto c-pagination__item">
                <button
                    class="c-button c-button--square ${item.active
                        ? `is-current`
                        : ''}"
                    data-page="${item.pagenum}"
                    data-href="${item.link}"
                    rel="${item.type}"
                    aria-current="${item.active ? `page` : nothing}"
                    @click="${this._onPageClick}"
                >
                    <span class="c-button__text">${item.pagenum}</span>
                </button>
            </li>
        `;
    }

    _navListTemplate(items) {
        return html`
            <div class="o-grid__col--auto">
                <ul class="o-grid o-grid--gutter-xxs">
                    ${items?.map((item) => this._navigationItemTemplate(item))}
                </ul>
            </div>
        `;
    }

    _pageListTemplate(items) {
        return html`
            <div class="c-pagination__list o-grid__col--auto">
                <ol class="o-grid o-grid--gutter-xxs">
                    ${items?.map((item) => this._pageItemTemplate(item))}
                </ol>
            </div>
        `;
    }

    _loadMoreButtonTemplate() {
        return html`
            <button
                class="c-button c-button--primary c-button--load-more ${this
                    .isLoading
                    ? `is-loading`
                    : ''}"
                rel="next"
                @click="${this._onLoadMoreClick}"
            >
                <span class="c-button__text">
                    ${this.isLoading ? html`${this.i18n.loading}...` : html`${this.i18n.loadMore}`}
                </span>
            </button>
        `;
    }

    updated(_changedProperties) {
        this._currentPage = this.pagination.currentPage;
    }

    render() {
        // console.log('hasUpdated (pagination)', this.hasUpdated);

        const {
            currentPage,
            navigationLinks,
            numberOfEntries,
            pageLimit,
            pageLinks,
        } = this.pagination;

        // Don’t show the pagination if there’s only one page
        if (pageLinks?.length < 2) {
            return;
        }

        if (this.isSmallViewport || this.infiniteOnly) {
            // Don’t show the load more button if all items can be shown
            if (currentPage * pageLimit > numberOfEntries) {
                return;
            }

            return this._loadMoreButtonTemplate();
        }

        return html`
            <nav
                class="c-pagination"
                aria-label="pagination"
            >
                <div class="c-pagination__container o-grid o-grid--gutter-xxs">
                    ${navigationLinks.prepend?.length
                        ? html`${this._navListTemplate(
                              navigationLinks.prepend
                          )}`
                        : nothing}
                    ${pageLinks?.length
                        ? html`${this._pageListTemplate(pageLinks)}`
                        : nothing}
                    ${navigationLinks.append?.length
                        ? html`${this._navListTemplate(navigationLinks.append)}`
                        : nothing}
                </div>
            </nav>
        `;
    }
}

customElements.define('lf-pagination', LfPagination);
