const ConvertRemToPixels = (SuperClass) =>
    class extends SuperClass {
        convertRemToPixels(rem) {
            return (
                parseFloat(rem) *
                parseFloat(getComputedStyle(document.documentElement).fontSize)
            );
        }
    };

export default ConvertRemToPixels;
