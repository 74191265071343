/**
 * @fileoverview WCAG compliant disclosure navigation component
 */

class DisclosureNav {
    constructor($module) {
        this.rootNode = $module;
        this.useArrowKeys = true;
        this.handleHeaderHide = this.handleHeaderHide.bind(this);
        this.allMenus = [];
        this.expandedMenuIndex = null;
        this.allRootActions = [
            ...this.rootNode.querySelectorAll('.js-disclosure-nav-toggle'),
        ];
    }

    init() {
        if (!this.rootNode || !this.allRootActions.length) {
            return;
        }

        window.addEventListener('headerHide', this.handleHeaderHide);
        this.rootNode.addEventListener('focusout', this.onBlur.bind(this));

        this.allRootActions.forEach((rootAction) => {
            const isMenuToggle =
                rootAction.tagName.toLowerCase() === 'button' &&
                rootAction.hasAttribute('aria-controls');

            // If it’s a menu toggle, set it up accordingly
            if (isMenuToggle) {
                const controlledMenuId =
                    rootAction.getAttribute('aria-controls');
                const controlledMenu =
                    document.getElementById(controlledMenuId);

                // Save a reference of all the menus
                this.allMenus.push(controlledMenu);

                // Make sure the menus are collapsed initially
                rootAction.setAttribute('aria-expanded', 'false');
                this.toggleMenu(controlledMenu, false);

                // Attach event listeners
                controlledMenu.addEventListener(
                    'keydown',
                    this.onMenuKeyDown.bind(this)
                );
                rootAction.addEventListener(
                    'click',
                    this.onMenuToggleClick.bind(this)
                );
                rootAction.addEventListener(
                    'keydown',
                    this.onMenuToggleKeyDown.bind(this)
                );
            }

            // If it’s a top-level link, set it up accordingly
            else {
                this.allMenus.push(null);
                rootAction.addEventListener(
                    'keydown',
                    this.onRootLinkKeyDown.bind(this)
                );
            }
        });
    }

    handleHeaderHide(event) {
        this.close();
    }

    moveFocusByArrowKey(event, nodeList, currentIndex) {
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowLeft':
                event.preventDefault();
                if (currentIndex > -1) {
                    let prevIndex = Math.max(0, currentIndex - 1);
                    nodeList[prevIndex].focus();
                }
                break;

            case 'ArrowDown':
            case 'ArrowRight':
                event.preventDefault();

                if (currentIndex > -1) {
                    let nextIndex = Math.min(
                        nodeList.length - 1,
                        currentIndex + 1
                    );
                    nodeList[nextIndex].focus();
                }
                break;

            case 'Home':
                event.preventDefault();
                nodeList[0].focus();
                break;

            case 'End':
                event.preventDefault();
                nodeList[nodeList.length - 1].focus();
                break;
        }
    }

    onBlur(event) {
        let isFocusInside = this.rootNode.contains(event.relatedTarget);
        if (!isFocusInside && this.expandedMenuIndex !== null) {
            this.toggleMenuToggle(this.expandedMenuIndex, false);
        }
    }

    // const isExpanded = $toggle.getAttribute('aria-expanded') === 'true' || false;
    onMenuToggleClick(event) {
        let clickedMenuToggle = event.target;
        let clickedMenuToggleIndex =
            this.allRootActions.indexOf(clickedMenuToggle);
        let isExpanded =
            clickedMenuToggle.getAttribute('aria-expanded') === 'true';
        this.toggleMenuToggle(clickedMenuToggleIndex, !isExpanded);
    }

    onMenuToggleKeyDown(event) {
        let clickedMenuToggleIndex = this.allRootActions.indexOf(
            document.activeElement
        );

        // Close if Escape key is pressed
        if (event.key === 'Escape') {
            this.toggleMenuToggle(this.expandedMenuIndex, false);
        }

        // On ArrowDown Move focus into the open menu
        else if (
            this.useArrowKeys &&
            this.expandedMenuIndex === clickedMenuToggleIndex &&
            event.key === 'ArrowDown'
        ) {
            event.preventDefault();
            this.allMenus[this.expandedMenuIndex].querySelector('a').focus();
        }

        // If set, move focus by arrow keys between top-level buttons and links
        else if (this.useArrowKeys) {
            this.moveFocusByArrowKey(
                event,
                this.allRootActions,
                clickedMenuToggleIndex
            );
        }
    }

    onRootLinkKeyDown(event) {
        let targetLinkIndex = this.allRootActions.indexOf(
            document.activeElement
        );

        // If set, move the focus between top-level actions
        if (this.useArrowKeys) {
            this.moveFocusByArrowKey(
                event,
                this.allRootActions,
                targetLinkIndex
            );
        }
    }

    onMenuKeyDown(event) {
        if (this.expandedMenuIndex === null) {
            return;
        }

        let menuLinks = Array.prototype.slice.call(
            this.allMenus[this.expandedMenuIndex].querySelectorAll('a')
        );

        let currentIndex = menuLinks.indexOf(document.activeElement);

        // Close the menu on Escape key press
        if (event.key === 'Escape') {
            this.allRootActions[this.expandedMenuIndex].focus();
            this.toggleMenuToggle(this.expandedMenuIndex, false);
        }

        // If set, handle arrow key navigation within menu links
        else if (this.useArrowKeys) {
            this.moveFocusByArrowKey(event, menuLinks, currentIndex);
        }
    }

    toggleMenuToggle(index, isExpanded) {
        // If another menu is currently open, close it first
        if (this.expandedMenuIndex !== index) {
            this.toggleMenuToggle(this.expandedMenuIndex, false);
        }

        // Handle current menu
        if (this.allRootActions[index]) {
            this.expandedMenuIndex = isExpanded ? index : null;
            this.allRootActions[index].setAttribute(
                'aria-expanded',
                isExpanded
            );
            this.toggleMenu(this.allMenus[index], isExpanded);
        }
    }

    toggleMenu(menu, shouldShow) {
        if (menu) {
            menu.hidden = !shouldShow;
        }
    }

    close() {
        this.toggleMenuToggle(this.expandedMenuIndex, false);
    }
}

export default DisclosureNav;
