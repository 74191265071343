import { html, svg, nothing } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';
import '../filter-facets/filter-facets.js';
import '../filter-status/filter-status.js';

class LfToolbar extends BaseCustomElement {
    static get properties() {
        return {
            hasError: { type: Boolean },
            isLoading: { type: Boolean },
            isSmallViewport: { type: Boolean },
            isFirstRun: { type: Boolean },
            hideFacets: { type: Boolean },
            pagination: { type: Object },
        };
    }

    constructor() {
        super();
        this.facetCount = 0;
        this.hasError = undefined;
        this.isLoading = undefined;
        this.isSmallViewport = false;
        this.isFirstRun = true;
        this.hideFacets = undefined;
        this.i18n = {};
        this.pagination = {
            currentPage: 1,
            pagesCount: 1,
            pageLimit: 0,
            pageLinks: [],
            navigationLinks: {
                prepend: [],
                append: [],
            },
            numberOfEntries: 0,
        };

        this._onFacetChange = this._onFacetChange.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        this.hideFacets = this.isSmallViewport;
        window.addEventListener('facets-change', this._onFacetChange);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('facets-change', this._onFacetChange);
    }

    _onFacetChange(event) {
        const newFacets = event.detail.facets;
        this.facetCount = newFacets.length;
    }

    // Templates
    // ------------------------------------------------------------------------

    _disclosureButtonTemplate() {
        // const isPlural = this.facetCount !== 1;

        return html`
            <button
                class="c-top-bar__disclosure-toggle c-link-button"
                aria-controls="disclosure-facets"
                aria-expanded="${this.hideFacets}"
                @click=${() => {
                    this.hideFacets = !this.hideFacets;
                }}
            >
                <svg
                    class="c-link-button__icon c-icon c-icon--sm"
                    aria-hidden="true"
                >
                    <use
                        xlink:href="/assets/icons/sprite.svg#chevron-up-24"
                    ></use>
                </svg>

                <span class="c-link-button__text u-text-sm u-color-primary">
                    Filterkriterien
                    ${this.hideFacets ? `einblenden` : `ausblenden`}
                </span>
            </button>
        `;
    }

    render() {
        return html`
            <div class="c-top-bar u-margin-bottom-3">
                <div class="c-top-bar__actions">
                    <lf-filter-status
                        class="c-top-bar__status"
                        ?hasError=${this.hasError}
                        ?isLoading=${this.isLoading}
                        .i18n=${this.i18n}
                        .itemsCount=${this.pagination.numberOfEntries}
                        .locale="${this.locale}"
                    ></lf-filter-status>

                    ${this.facetCount > 0
                        ? this._disclosureButtonTemplate()
                        : nothing}
                </div>

                <div
                    class="c-disclosure-panel u-margin-top-2"
                    id="disclosure-facets"
                    ?hidden="${this.hideFacets}"
                >
                    <lf-filter-facets
                        ?hasError=${this.hasError}
                        ?isLoading=${this.isLoading}
                        ?isFirstRun=${this.isFirstRun}
                        ?isSmallViewport=${this.isSmallViewport}
                        .filterFormId=${this.filterFormId}
                        .formData=${this.formData}
                        .i18n=${this.i18n}
                        .locale="${this.locale}"
                    ></lf-filter-facets>
                </div>
            </div>
        `;
    }
}

customElements.define('lf-toolbar', LfToolbar);

// ${this.facetCount ? this._disclosurePanelTemplate() : nothing}
// ${this.facetCount} ${isPlural ? `aktive` : `aktiver`}
