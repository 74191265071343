/* eslint-disable no-console */

import { html, nothing } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';
import './item.js';

class LfList extends BaseCustomElement {
    static get properties() {
        return {
            excerptLimit: { type: Number },
            hasError: { type: Boolean },
            isLoading: { type: Boolean },
            isFilteredByLastName: { type: Boolean },
            isFirstRun: { type: Boolean },
            items: { type: Array },
        };
    }

    constructor() {
        super();
        this.excerptLimit = 250;
        this.hasError = false;
        this.i18n = {};
        this.isFilteredByLastName = false;
        this.isLoading = undefined;
        this.isFirstRun = true;
        this.items = [];
        this.variant = '';
    }

    connectedCallback() {
        super.connectedCallback();
    }

    render() {
        // TODO: refactor notice into its own component
        if (!this.items.length && !this.isFirstRun) {
            return html`
                <div class="o-grid__col--12">
                    <article
                        class="c-breaking-news u-background-color-blue-lightest"
                    >
                        <div class="c-breaking-news__icon">
                            <span
                                class="u-background-color-blue-light u-padding-2@md u-display-inline-block"
                            >
                                <svg
                                    class="c-icon c-icon--xxl"
                                    aria-hidden="true"
                                >
                                    <use
                                        xlink:href="/assets/icons/sprite.svg#icon-alarm-120"
                                    ></use>
                                </svg>
                            </span>
                        </div>

                        <div
                            class="c-breaking-news__content u-padding-4 u-color-text "
                        >
                            <h2
                                class="c-breaking-news__headline u-text-h4 u-margin-bottom-2"
                            >
                                ${this.i18n.noResultsHeadline ?? nothing}
                            </h2>

                            <div class="c-breaking-news__text u-text-md">
                                <p>${this.i18n.noResultsMessage ?? nothing}</p>
                            </div>
                        </div>
                    </article>
                </div>
            `;
        }

        return this.items.map((item) => {
            return html`
                <lf-list-item
                    class="o-grid__col--12"
                    role="listitem"
                    .item=${item}
                    .excerptLimit=${this.excerptLimit}
                    .i18n=${this.i18n}
                    .variant=${this.variant}
                    ?isLoading=${this.isLoading}
                ></lf-list-item>
            `;
        });
    }
}

customElements.define('lf-list', LfList);
