/* eslint-disable no-console */

import { html, nothing } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';
import { prettyNumericDate } from '../utils.js';

class LfFilterFacets extends BaseCustomElement {
    static get properties() {
        return {
            formData: { type: Object },
            isLoading: { type: Boolean },
            isFirstRun: { type: Boolean },
            facets: { type: Array },
        };
    }

    constructor() {
        super();
        this.formData = new FormData();
        this.$form = '';
        this.$formElements = '';
        this.isLoading = false;
        this.facets = [];
        this.filterFormId = '';
        this.hasError = false;
        this.i18n = {};
        this.isFirstRun = true;
        this.isUserInteraction = false;
        this.itemsCount = 0;
        this.locale = 'de';
    }

    _isDateRange(name) {
        // this adds labels to date range facets exclusively on the publications filter
        // on publications startYears and end Years is a select so the condition for input date
        // doesnt apply
        const rangeNames = [
            'tx_lfpublications_list[filter][startYears]',
            'tx_lfpublications_list[filter][endYears]',
        ];
        return rangeNames.includes(name);
    }

    _getFacetText() {
        // Delete entries we know we never want to render as tags
        this.formData.delete('q');
        this.formData.delete('locale');
        this.formData.delete('page');

        // Start with a clean slate each time formData is changed
        this.facets = [];

        // Fetch the label text from the DOM, since it’s not in the FormData object
        for (const [name, value] of this.formData.entries()) {
            let text = '';

            if (!value || !name) continue;

            const $element = this.$form.querySelector(
                `input[type="checkbox"][name="${name}"], input[type="date"][name="${name}"], input[type="radio"][name="${name}"], select[name="${name}"]`
            );

            if (!$element) continue;
            const nodeName = $element.nodeName.toLowerCase();
            const type = $element.type;

            if (nodeName === 'select' && $element.options) {
                const addLabels = this._isDateRange($element.name);

                text = Array.from($element.options)
                    .find(($option) => $option.value === value)
                    .text.trim();
                if (addLabels) {
                    const label = $element.labels[0].textContent.trim();
                    text = `${label}: ${text}`;
                }
            } else if (nodeName === 'input') {
                if (type === 'date') {
                    let date = prettyNumericDate($element.value);
                    text = $element.labels[0].textContent.trim();
                    text = `${text}: ${date}`;
                } else if (type === 'checkbox') {
                    text = $element.labels[0].textContent.trim();
                } else if (type === 'radio') {
                    text = $element.labels[0].textContent.trim();
                } else {
                    text = $element.labels[0].textContent.trim();
                }
            }

            this.facets = [
                ...this.facets,
                { name: name, value: value, text: text },
            ];
        }
    }

    _onFacetClick(event) {
        const removedFacet = {
            name: event.target.getAttribute('data-name'),
            value: event.target.getAttribute('data-value'),
        };
        this._dispatchFacetRemoved(removedFacet);
    }

    _dispatchFacetRemoved(facet) {
        const event = new CustomEvent('facet-removed', {
            bubbles: true,
            detail: {
                name: facet.name,
                value: facet.value,
            },
        });
        this.dispatchEvent(event);
    }

    _dispatchFacetsChange() {
        const event = new CustomEvent('facets-change', {
            bubbles: true,
            detail: {
                facets: [...this.facets],
            },
        });
        this.dispatchEvent(event);
    }

    connectedCallback() {
        super.connectedCallback();
        this.$form = document.forms[this.filterFormId];
        this.formData = new FormData();
        this.$formElements = this.$form.elements;
    }

    willUpdate(changed) {
        if (changed.has('formData')) {
            this._getFacetText();
        }
    }

    updated(changed) {
        if (changed.has('facets')) {
            this._dispatchFacetsChange();
        }
    }

    render() {
        if (this.isFirstRun) {
            return;
        }

        return html`
            <ul class="c-tag__list c-tag__list--facets">
                ${this.facets?.map((facet) => {
                    return html`
                        <li class="c-tag__item is-current">
                            <button
                                class="c-tag__link"
                                data-name="${facet.name}"
                                data-value="${facet.value}"
                                @click=${this._onFacetClick}
                            >
                                <span class="c-tag__text">${facet.text}</span>
                                <span class="c-tag__icon">
                                    <svg
                                        class="c-icon c-icon--xs c-icon--cross-24"
                                        focusable="false"
                                        aria-hidden="true"
                                    >
                                        <use
                                            xlink:href="/assets/icons/sprite.svg#cross-24"
                                        />
                                    </svg>
                                </span>
                            </button>
                        </li>
                    `;
                })}
            </ul>
        `;
    }
}

customElements.define('lf-filter-facets', LfFilterFacets);
