class DisclosureButton {
    constructor(element) {
        this.$button = element;
        this.state = this.$button.getAttribute('aria-expanded');
        this.id = this.$button.getAttribute('aria-controls');
        this.$controlledElement = null;
    }

    init() {
        if (!this.$button) {
            return;
        }

        if (this.id) {
            this.$controlledElement = document.getElementById(this.id);
        }

        if (this.state === 'true') {
            this.show();
        } else {
            this.hide();
        }

        this.$button.addEventListener(
            'click',
            this.handleButtonClick.bind(this)
        );
    }

    show() {
        this.$button.setAttribute('aria-expanded', 'true');
        if (this.$controlledElement) {
            this.$controlledElement.classList.remove('is-hidden');
            this.$controlledElement.removeAttribute('hidden');
        }
    }

    hide() {
        this.$button.setAttribute('aria-expanded', 'false');
        if (this.$controlledElement) {
            this.$controlledElement.classList.add('is-hidden');
            this.$controlledElement.setAttribute('hidden', 'true');
        }
    }

    toggle() {
        if (this.$button.getAttribute('aria-expanded') === 'true') {
            this.hide();
        } else {
            this.show();
        }
    }

    handleButtonClick() {
        this.toggle();
    }
}

export default DisclosureButton;
