import Swiper from 'swiper';
import SwiperCore, {
    Navigation,
    Pagination,
    Controller,
    EffectFade,
    Autoplay,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Controller, EffectFade, Autoplay]);

const config = {
    // CSS class names
    containerModifierClass: 'c-slideshow__container--',
    wrapperClass: 'js-slideshow__list',
    slideClass: 'js-slideshow__item',
    slideVisibleClass: 'h-is-visible',
    slideBlankClass: 'is-blank',
    slidePrevClass: 'is-previous',
    slideActiveClass: 'is-active',
    slideNextClass: 'is-next',
    simulateTouch: false,

    // Options
    watchOverflow: true,

    // effect: 'slide', // "slide", "fade", "cube", "coverflow" or "flip"
    // speed: 800,
    grabCursor: true,

    // loop: false,
    // slidesPerView: 1,
    // autoplay: true,

    // Add pagination bullets and show current slide fraction
    pagination: {
        type: 'bullets', // "bullets", "fraction", "progressbar" or "custom"
        clickable: true,

        // CSS selector or DOM element
        el: '.js-slideshow__status',

        // CSS class names
        modifierClass: 'c-slideshow__status--',
        clickableClass: 'c-slideshow__status--clickable',
        hiddenClass: 'is-hidden',
        lockClass: 'is-disabled',
        bulletActiveClass: 'c-slideshow__bullet--is-active',
        bulletClass: 'c-slideshow__bullet',
        currentClass: 'c-slideshow__status__is-current',
        totalClass: 'c-slideshow__fraction-total',
    },

    // Add previous/next buttons
    navigation: {
        // CSS selector or DOM element
        prevEl: '.js-slideshow__arrow--previous',
        nextEl: '.js-slideshow__arrow--next',

        // CSS class names
        disabledClass: 'is-disabled',
        hiddenClass: 'is-hidden',
        lockClass: 'is-locked',
    },

    a11y: {
        enabled: true,
        notificationClass: 'c-slideshow__live-region',
        prevSlideMessage: 'prevSlideMessage: Previous slide',
        nextSlideMessage: 'nextSlideMessage: Next slide',
        firstSlideMessage: 'firstSlideMessage: This is the first slide',
        lastSlideMessage: 'lastSlideMessage: This is the last slide',
        paginationBulletMessage:
            'paginationBulletMessage: Go to slide {{index}}',
        containerMessage: 'containerMessage',
        containerRoleDescriptionMessage: 'Group?', // TODO: check aria specs for correct role
        itemRoleDescriptionMessage: 'Item?', // TODO: check aria specs for correct role
    },
};

class Slideshow {
    constructor($node, index, $nodeList) {
        this.$node = $node;
        this.index = index;
        this.$nodeList = $nodeList;
        this.showFractions = true;

        this.fractions = $node.querySelector('.c-slideshow__fraction');

        this.className = `js-slideshow-${this.index + 1}`;

        /*
        this.options = JSON.parse(this.$node.dataset.slideshow);
        this.config = this.options.length
            ? Object.assign(this.options, config)
            : config;
        */
        if (this.$node.dataset.slideshow !== undefined) {
            const thisSwiperConfig = JSON.parse(this.$node.dataset.slideshow);
            const mergedSwiperConfig = Object.assign(thisSwiperConfig, config);
            this.config = mergedSwiperConfig;
        } else {
            this.config = config;
        }

        this.instances = [];
        this.fraction = '';
        this.swiper;

        // this.onResize = this.onResize.bind(this);
        this.appendFraction = this.appendFraction.bind(this);
    }

    init() {
        if (!this.$node) {
            return;
        }

        this.$node.classList.add(this.className);
        this.swiper = new Swiper(`.${this.className}`, this.config);

        if (this.showFractions) {
            this.appendFraction();
            this.swiper.on('slideChange', this.appendFraction);
        }

        // window.addEventListener('resize', this.onResize);
    }

    appendFraction() {
        if (this.fractions) {
            this.fractions.textContent = `${this.swiper.realIndex + 1} / ${
                this.swiper.slides.length
            }`;
        }
    }
}

export default Slideshow;
