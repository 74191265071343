
let $tippyInstances = []
function imageMapJS($elem) {


    const id = $elem.getAttribute('data-template');
    const template = document.getElementById(id);
    tippy($elem, {
        trigger: 'click',
        // never hide upon clicking
        hideOnClick: false,
        content: template.innerHTML,
        allowHTML: true,
        animation: 'shift-away',
        interactive: true,
        theme: 'hswt',
        placement: 'top-start',
        maxWidth: 350,

        onShow(instance) {
            $tippyInstances.forEach(item => item.hide())
            $tippyInstances.push(instance)
 
            // check position and change placement if too close to right window edge
            const $reference = instance.reference
            const offsetRight = windowOffsetRight($reference)
            if (offsetRight < 350){
                instance.setProps({
                    placement: 'top-end',
                });
            }

            isInfoButton(instance.reference) && instance.popper.classList.add('popper-info')
            instance.popper
                .querySelector('.c-imagemap__close-btn')
                .addEventListener('click', instance.hide);
        },
        onHide(instance) {
            $tippyInstances = $tippyInstances.filter(item => item.id == instance.id)
            instance.popper
                .querySelector('.c-imagemap__close-btn')
                .removeEventListener('click', instance.hide);
        },
    });

    window.addEventListener("load",  () => {

        // checking for image map width and resizing
        if (!document.querySelector('.c-imagemap')) return;
        let ro = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.contentRect.width >= 480){
                    document.querySelector('.c-imagemap').classList.remove('c-imagemap__mobile')
                    destroyMobileTooltip()
                    tippyEnable()
                }
                else {
                    document.querySelector('.c-imagemap').classList.add('c-imagemap__mobile')
                    tippyDisable()
                    initMobileTooltip()
                }
            }
        
        });
        // Observe one or multiple elements
        ro.observe(document.querySelector('.c-imagemap'))
          
        


    })
    function isInfoButton(elem){
        return elem.classList.contains('c-imagemap__pin--info')
        
    }
    function windowOffsetRight(ele) { 
        let rect = ele.getBoundingClientRect(); 
        let width = document.querySelector('.c-imagemap').getBoundingClientRect().width;
        return  width - ( rect.x + rect.width)
    }

    function initMobileTooltip(){
        document.querySelector('.c-imagemap').addEventListener('click', mobileClickHandler);
    }

    function destroyMobileTooltip(){
        document.querySelector('.c-imagemap').removeEventListener('click', mobileClickHandler);
    }

    // clickHandler non-tippy (for mobile)
    function mobileClickHandler(event){
       const $targetElem = event.target.closest('.c-imagemap__place')
       if (!$targetElem) return;
       const $cloud = $targetElem.querySelector('.c-imagemap__cloud') 
       openMobileTooltip($cloud)
    }

    // close function non-tippy (for mobile)
    function close(event){
        const $el = event.target && event.target.closest('.cloud-wrapper') || document.querySelector('.cloud-wrapper')
        document.querySelector('.c-imagemap__wrapper').removeChild($el)
    }

   function tippyEnable(){
        const $tippyToolTips = document.querySelectorAll('[data-template]');
        for (let $toolTip of $tippyToolTips){
            $toolTip._tippy.enable();
        }
    }

    function openMobileTooltip(cloud){
        // close all previous ones on open
        const $previousClouds = document.querySelectorAll('.cloud-wrapper')
        if ($previousClouds.length != 0){
            for (let $previousCloud of $previousClouds){
                close($previousCloud)
            }

        }
        // create wrapper div cloud-wrapper
        const $cloudWrapper = document.createElement('div')
        $cloudWrapper.classList.add('cloud-wrapper')
        // clone content and display in cloud wrapper
        const $cloud = cloud.cloneNode(true)
        //add close event listener to close_btn
        $cloud.querySelector('.c-imagemap__close-btn').addEventListener('click', close)
        // insert cloud content
        $cloudWrapper.insertAdjacentElement('afterbegin', $cloud)
        document.querySelector('.c-imagemap').insertAdjacentElement('afterend', $cloudWrapper)
 
        
    }

    function tippyDisable(){
        const $tippyToolTips = document.querySelectorAll('[data-template]');
        for (let $toolTip of $tippyToolTips){
            $toolTip._tippy.disable();
        }
    }
}
export default imageMapJS;
