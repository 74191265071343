class TeaserHasLink {
    constructor($module) {
        this.$teaser = $module;
        this.$mainLink = this.$teaser.querySelector('.c-teaser__link');

        // bind `this` to methods where needed
        this.addClickListener = this.addClickListener.bind(this);
        this.handleTeaserClick = this.handleTeaserClick.bind(this);
    }
    init() {
        if (!this.$teaser || !this.$mainLink) {
            return;
        }
        window.addEventListener(
            'DOMContentLoaded',
            this.addClickListener
        );
    }
    addClickListener() {
        window.removeEventListener('DOMContentLoaded', this.addClickListener);
        const $clickables = this.$teaser.querySelectorAll('a, button');

        // Listen for clicks on teaser
        this.$teaser.addEventListener(
            'click',
            this.handleTeaserClick
        );

        // Listen for clicks on clickable elements inside teaser
        $clickables.forEach(function ($clickable) {
            $clickable.addEventListener('click', function (event) {
                // Don’t bubble up and trigger main link when a button or link is clicked
                event.stopPropagation();
            });
        });
    }
    // Trigger main link when teaser is clicked, unless text
    // is being selected
    // TODO: handle clicks on modal buttons
    handleTeaserClick(event) {
        const noTextSelected = !window.getSelection().toString();
        // const isNotFancyBox = !event.target.hasAttribute('data-fancybox');
        // if (noTextSelected && isNotFancyBox) {
        if (noTextSelected) {
            this.$mainLink.click();
        }
    }
}

export default TeaserHasLink;
