import { html } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';

class LfFilterStatus extends BaseCustomElement {
    static get properties() {
        return {
            data: { type: Object },
            hasError: { type: Boolean },
            isLoading: { type: Boolean },
            isUserInteraction: { type: Boolean },
            itemsCount: { type: Number },
            i18n: { type: Object},
            locale: { type: String}

        };
    }

    constructor() {
        super();
        this.data = {};
        this.hasError = false;
        this.isLoading = false;
        this.isUserInteraction = false;
        this.itemsCount = 0;
        this.i18n = {};
        this.le = 'de'
    


    }
    _getLocale(){
        
       return document.querySelector('html').lang.startsWith('en') ? 'en' : 'de'
       
    }

    _getStatusText() {
        if (this.isLoading) {
            // api result not yet available
            this.le = this._getLocale();

            const loading = this.le != 'de' ? 'loading' : 'Lädt';
            return html`${loading}...`;
        }


        const resultWordDE = this.itemsCount !== 1 ? `Ergebnisse` : `Ergebnis`;
        const resultWordEN = this.itemsCount !== 1 ? `results` : `result`;
        const resultWord = this.le == 'de' ? resultWordDE : resultWordEN;

        return html`${this.itemsCount} ${resultWord}`;
    }

    render() {
        return html`
            <p class="u-text-sm u-text-bold u-color-primary">
                ${this._getStatusText()}
            </p>
        `;
    }
}

customElements.define('lf-filter-status', LfFilterStatus);
