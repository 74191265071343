import { html, nothing, svg } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';

class LfButton extends BaseCustomElement {
    static get properties() {
        return {
            text: { type: String, attribute: true },
            icon: { type: String },
            hasError: { type: Boolean },
            isLoading: { type: Boolean },
        };
    }

    constructor() {
        super();
        this.text = '';
        this.icon = '';
        this.hasError = false;
        this.isLoading = false;
        this.isUserInteraction = false;

        this.i18n = {};
        this.locale = 'de';
    }

    _iconTemplate(icon) {
        return svg`
            <svg
                class="c-button__icon c-icon"
                aria-hidden="true"
            >
                <use href="/assets/icons/sprite.svg#${icon}" />
            </svg>
        `;
    }

    _overlayTemplate() {
        return svg`
            <div class="c-button__overlay">
              <svg class="c-button__spinner" viewBox="0 0 50 50">
                <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
              </svg>
            </div>
        `;
    }

    render() {
        return html`
            <button
                class="c-button c-button--spinner c-button--primary"
                type="button"
            >
                <span class="c-button__text">${this.text}</span>
                ${this.isLoading ? this._overlayTemplate() : nothing}
            </button>
        `;
    }
}

customElements.define('lf-button', LfButton);

// ${this.isLoading ? 'Lädt' : this.text}
// ${this.isLoading ? this._overlayTemplate() : nothing}
