import Swiper from 'swiper';
import SwiperCore, {
    Navigation,
    Pagination,
    Controller,
    EffectFade,
    Autoplay,
} from 'swiper/core';

SwiperCore.use([Navigation, Pagination, Controller, EffectFade, Autoplay]);

// TODO: change config based on the element's data attributes

const swiperConfig = {
    // CSS class names
    containerModifierClass: 'c-slideshow__container--',
    wrapperClass: 'js-slideshow__list',
    slideClass: 'js-slideshow__item',
    slideVisibleClass: 'h-is-visible',
    slideBlankClass: 'is-blank',
    slidePrevClass: 'is-previous',
    slideActiveClass: 'is-active',
    slideNextClass: 'is-next',

    // Options
    watchOverflow: true,

    // effect: 'slide', // "slide", "fade", "cube", "coverflow" or "flip"
    // speed: 800,
    grabCursor: true,

    // loop: false,
    // slidesPerView: 1,
    // autoplay: false,

    // Add pagination bullets and show current slide fraction
    pagination: {
        type: 'bullets', // "bullets", "fraction", "progressbar" or "custom"
        clickable: true,

        // CSS selector or DOM element
        el: '.js-slideshow__status',

        // CSS class names
        modifierClass: 'c-slideshow__status--',
        clickableClass: 'c-slideshow__status--clickable',
        hiddenClass: 'is-hidden',
        lockClass: 'is-disabled',
        bulletActiveClass: 'c-slideshow__bullet--is-active',
        bulletClass: 'c-slideshow__bullet',
        currentClass: 'c-slideshow__status__is-current',
        totalClass: 'c-slideshow__fraction-total',
    },

    // Add previous/next buttons
    navigation: {
        // CSS selector or DOM element
        prevEl: '.js-slideshow__arrow--previous',
        nextEl: '.js-slideshow__arrow--next',

        // CSS class names
        disabledClass: 'is-disabled',
        hiddenClass: 'is-hidden',
        lockClass: 'is-locked',
    },

    // Add aria roles and accessible text
    a11y: {
        enabled: true,
        notificationClass: 'c-slideshow__live-region',
        prevSlideMessage: 'prevSlideMessage: Previous slide',
        nextSlideMessage: 'nextSlideMessage: Next slide',
        firstSlideMessage: 'firstSlideMessage: This is the first slide',
        lastSlideMessage: 'lastSlideMessage: This is the last slide',
        paginationBulletMessage:
            'paginationBulletMessage: Go to slide {{index}}',
        containerMessage: 'containerMessage',
        containerRoleDescriptionMessage: 'Group?', // TODO: check aria specs for correct role
        itemRoleDescriptionMessage: 'Item?', // TODO: check aria specs for correct role
    },
};

class Storyslider {
    constructor($slideshows) {
        this.$slideshows = $slideshows;
        this.swiper = [];

        // bind `this` to methods where needed
        this.onResize = this.onResize.bind(this);
    }
    init() {
        if (!this.$slideshows.length) {
            return;
        }

        for (const [index, $slideshow] of [...this.$slideshows].entries()) {
            const indexClass = 'js-storyslider-' + index;
            $slideshow.classList.add(indexClass);

            if ($slideshow.dataset.slideshow !== undefined) {
                const thisSwiperConfig = JSON.parse(
                    $slideshow.dataset.slideshow
                );

                // console.log('thisSwiperConfig', thisSwiperConfig);

                const mergedSwiperConfig = Object.assign(
                    thisSwiperConfig,
                    swiperConfig
                );

                // console.log('mergedSwiperConfig', mergedSwiperConfig);

                this.swiper[index] = new Swiper(
                    '.' + indexClass,
                    mergedSwiperConfig
                );
            } else {
                // console.log('swiperConfig', swiperConfig);
                this.swiper[index] = new Swiper('.' + indexClass, swiperConfig);
            }

            // this.positionArrows($slideshow);
        }

        window.addEventListener('resize', this.onResize);
    }

    /*
    positionArrows($slideshow) {
        const $arrowPrevious = $slideshow.getElementsByClassName(
            'js-slideshow__arrow--previous'
        )[0];
        const $arrowNext = $slideshow.getElementsByClassName(
            'js-slideshow__arrow--next'
        )[0];

        if (!$arrowPrevious || !$arrowNext) {
            return;
        }

        const $mediaAspectRatio =
            $slideshow.getElementsByClassName('c-media')[0];
        const topValue = $mediaAspectRatio.offsetHeight / 2 + 'px';

        for (const $arrow of [$arrowPrevious, $arrowNext]) {
            $arrow.style.top = topValue;
        }
    }
    */
    onResize() {
        return;

        /*
        for (const $slideshow of [...this.$slideshows]) {
            this.positionArrows($slideshow);
        }
        */
    }
}

export default Storyslider;
