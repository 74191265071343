import trapFocus from '../../../scripts/modules/trap-focus';

/**
 * @class Sliding Navigation
 */
class NavMobile {
    $element;
    id;
    previouslyFocused = null;
    isShown = false;

    constructor(element) {
        this.$element = element;
        this.id =
            this.$element.getAttribute('data-nav-mobile') || this.$element.id;

        this.$element.setAttribute('aria-hidden', 'true');
        this.$element.setAttribute('aria-modal', 'true');
        this.$element.setAttribute('tabindex', '-1');

        document.addEventListener('click', this.handleTriggerClick, true);
    }

    show = (event) => {
        if (this.isShown) return this;

        // Keep a reference to the currently focused element
        // to be able to restore it later.
        this.previouslyFocused = document.activeElement;
        this.isShown = true;
        this.$element.removeAttribute('aria-hidden');

        moveFocusToPanel(this.$element);

        document.body.addEventListener('focus', this.handleOutsideFocus, true);
        this.$element.addEventListener('keydown', this.handleKeyDown, true);
        document
            .getElementById('nav-mobile')
            .addEventListener('hide', this.handleNavDrawerHide, true);

        return this;
    };

    hide = (event) => {
        if (!this.isShown) return this;

        this.isShown = false;

        this.$element.setAttribute('aria-hidden', 'true');

        this.previouslyFocused
            ? this.previouslyFocused.focus()
            : document.body.focus();

        document.body.removeEventListener(
            'focus',
            this.handleOutsideFocus,
            true
        );
        this.$element.removeEventListener('keydown', this.handleKeyDown, true);

        return this;
    };

    destroy = () => {
        this.hide();

        // Remove the click event delegates for our openers and closers
        document.removeEventListener('click', this.handleTriggerClick, true);

        return this;
    };

    handleTriggerClick = (event) => {
        const target = event.target;
        if (target.matches(`[data-nav-mobile-show="${this.id}"]`)) {
            this.show(event);
        }
        if (
            target.matches(`[data-nav-mobile-hide="${this.id}"]`) ||
            (target.matches('[data-nav-mobile-hide]') &&
                target.closest('[aria-modal="true"]') === this.$element)
        ) {
            this.hide(event);
        }
    };

    handleKeyDown = (event) => {
        if (
            document.activeElement?.closest('[aria-modal="true"]') !==
            this.$element
        ) {
            return;
        }

        if (event.key === 'Escape') {
            event.preventDefault();
            this.hide(event);
        }

        if (event.key === 'Tab') {
            trapFocus(this.$element, event);
        }
    };

    handleOutsideFocus = (event) => {
        if (!event.target.closest('[aria-modal="true"]'))
            moveFocusToPanel(this.$element);
    };

    handleNavDrawerHide = (event) => {
        this.hide(event);
    };
}

function moveFocusToPanel(element) {
    const focusCandidate =
        element.querySelector('[autofocus]') ||
        element.querySelector('a, button') ||
        element;
    focusCandidate.focus();
}

export default NavMobile;
