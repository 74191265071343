/**
 *
 * Show loader on submit button while submitting
 *
 */
function loadingButton() {
    var callback = function () {
        var filterSubmitElem = document.querySelector(
            '[data-js-selector="filter-submit"]'
        );

        // var filterListElem = document.querySelector('[data-js-selector="filter-list"]');

        if (!filterSubmitElem) {
            return;
        }

        filterSubmitElem.addEventListener('click', handleFilterSubmit);

        function handleFilterSubmit() {
            filterSubmitElem.removeEventListener('click', handleFilterSubmit);
            setTimeout(function () {
                filterSubmitElem.removeAttribute('type');
                filterSubmitElem.setAttribute('disabled', 'disabled');
                filterSubmitElem.classList.add('c-button--loading');

                // filterListElem.style.display = 'none';
            }, 0);
        }
    };

    if (
        document.readyState === 'complete' ||
        (document.readyState !== 'loading' && !document.documentElement.doScroll)
    ) {
        callback();
    } else {
        document.addEventListener('DOMContentLoaded', callback);
    }
}
export default loadingButton;

/*
var filterSubmitElem = document.querySelector(
	'[data-js-selector="filter-submit"]'
);

$(".c-form").parsley().on('form:validate', function (formInstance) {

    if ( formInstance.isValid() ) {
        filterSubmitElem.removeAttribute('type');
        filterSubmitElem.setAttribute('disabled','disabled');
        filterSubmitElem.classList.add('button--loading');
    }
});

*/
