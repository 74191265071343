import { Fancybox } from '@fancyapps/ui';

/**
 * Helper to generate custom caption HTML.
 *
 * Wraps the image the credits for styling purposes and
 * adds a counter if the image is part of a group.
 *
 * @param fancybox
 * @param carousel
 * @param slide
 */
const generateCaption = (fancybox, carousel, slide) => {
    slide.caption =
        carousel.slides.length > 1
            ? `<span class="fancybox__index">${
                  slide.index + 1
              }<span class="fancybox__count">/${
                  carousel.slides.length
              } </span></span> ${slide.caption}`
            : slide.caption;

    slide.caption = slide.credits
        ? `${slide.caption}<span class="c-media__credits">${slide.credits}</span>`
        : slide.caption;

    return slide.caption;
};

// Icons
const CLOSE_BUTTON_ICON =
    '<svg class="c-icon c-icon--sm" aria-hidden="true" focusable="false"><use xlink:href="/assets/icons/sprite.svg#cross-24"></use></svg>';
const SPINNER_ICON =
    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="24 24 48 48" aria-hidden="true" focusable="false"><circle cx="48" cy="48" r="20"/></svg>';
const PREVIOUS_BUTTON_ICON =
    '<svg class="c-icon" aria-hidden="true" focusable="false"><use xlink:href="/assets/icons/sprite.svg#chevron-left-24"></use></svg>';
const NEXT_BUTTON_ICON =
    '<svg class="c-icon" aria-hidden="true" focusable="false"><use xlink:href="/assets/icons/sprite.svg#chevron-right-24"></use></svg>';

// Defaults
Fancybox.defaults.infinite = false;
Fancybox.defaults.Toolbar = false;
Fancybox.defaults.dragToClose = true;
Fancybox.defaults.hideScrollbar = true;
Fancybox.defaults.template = {
    closeButton: CLOSE_BUTTON_ICON,
    spinner: SPINNER_ICON,
    main: null,
};
Fancybox.defaults.Carousel = {
    Navigation: {
        classNames: {
            main: 'carousel__nav',
            button: 'carousel__button c-button c-button--icon-only',
            next: 'is-next',
            prev: 'is-prev',
        },
        prevTpl: PREVIOUS_BUTTON_ICON,
        nextTpl: NEXT_BUTTON_ICON,
    },
};

// Regular image lightbox
Fancybox.bind('[data-lightbox]:not([data-type="iframe"])', {
    closeButton: 'outside',
    groupAttr: 'data-group',
    caption: generateCaption,
});

// Lightbox showing iframe content
Fancybox.bind('[data-lightbox][data-type="iframe"]', {
    closeButton: 'inside',
});
