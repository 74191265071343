/* eslint-disable no-console */

import { html, nothing } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';
import '../button/button.js';

function toNumber(string) {
    return parseInt(string, 10);
}

class LfFilterForm extends BaseCustomElement {
    static get properties() {
        return {
            filters: { type: Object },
            hasError: { type: Boolean },
            isLoading: { type: Boolean },
        };
    }

    constructor() {
        super();
        this.$form = '';
        this.formData = {};
        this.filters = {};
        this.isLoading = false;
        this.hasError = false;

        this._bindEventListeners();
    }

    _bindEventListeners() {
        this._onApiRequest = this._onApiRequest.bind(this);
        this._onApiResponse = this._onApiResponse.bind(this);
        this._onFacetRemoved = this._onFacetRemoved.bind(this);
        this._onFormElementChange = this._onFormElementChange.bind(this);
        this._onFormReset = this._onFormReset.bind(this);
        this._onFormSubmit = this._onFormSubmit.bind(this);
    }

    _addEventListeners(event) {
        Array.from(this.$formElements).forEach(($elem) => {
            $elem.addEventListener('change', this._onFormElementChange);
        });
        this.$form.addEventListener('submit', this._onFormSubmit);
        this.$resetButton.addEventListener('click', this._onFormReset);
        window.addEventListener('api-request', this._onApiRequest);
        window.addEventListener('api-response', this._onApiResponse);
        window.addEventListener('facet-removed', this._onFacetRemoved);
    }

    _removeEventListeners(event) {
        Array.from(this.$formElements).forEach(($elem) => {
            $elem.removeEventListener('change', this._onFormElementChange);
        });
        this.$form.removeEventListener('submit', this._onFormSubmit);
        this.$resetButton.removeEventListener('click', this._onFormReset);
        window.removeEventListener('api-request', this._onApiRequest);
        window.removeEventListener('api-response', this._onApiResponse);
        window.removeEventListener('facet-removed', this._onFacetRemoved);
    }

    _unselectSelection(name, value) {
        if (!name || !value) return;

        const $element = this.$form.querySelector(
            `input[type="checkbox"][name="${name}"], input[type="date"][name="${name}"], input[type="radio"][name="${name}"], select[name="${name}"]`
        );

        // Sanity check
        if (!$element) return;

        const nodeName = $element.nodeName.toLowerCase();
        const type = $element.type;

        if (nodeName === 'select' && $element.options) {
            // Get index of the `<option>` that's to be de-selected, we can
            // reuse that to find the corresponding SumoSelect `<li>`
            const selectedIndex = Array.from($element.options).findIndex(
                (opt) => opt.value === value
            );

            const $option = $element.options[selectedIndex];
            const $sumoOption = $element
                .closest('.SumoSelect')
                .querySelector('.optWrapper ul.options').children[
                selectedIndex
            ];

            // De-select the corresponding SumoSelect `<li>`
            $sumoOption.classList.remove('selected');

            // De-select the the native `<option>`
            $option.selected = false;

            if (!$element.multiple) {
                const firstText = $element.options[0].text;
                $element.value = '';
                $element.selectedIndex = 0;
                $element
                    .closest('.SumoSelect')
                    .querySelector('.CaptionCont.SelectBox span').innerHTML =
                    firstText;
            }
        } else if (nodeName === 'input') {
            if (type === 'checkbox' || type === 'radio') {
                $element.checked = false;
            } else {
                $element.value = '';
            }
        }
    }

    _disableAllFilters() {
        const $inputs = this.$form.querySelectorAll(
            'input[type="checkbox"], input[type="date"], input[type="radio"]'
        );
        $inputs.forEach(($input) => {
            $input.disabled = true;
        });

        const $sumoOptions = this.$form.querySelectorAll('.SumoSelect .opt');
        $sumoOptions.forEach(($sumoOption) => {
            $sumoOption.classList.add('is-disabled');
            $sumoOption.setAttribute('aria-disabled', 'true');
        });

        const $selects = this.$form.querySelectorAll('select');
        $selects.forEach(($select) => {
            Array.from($select.options).forEach(($option) => {
                $option.disabled = true;
            });

            if (!$select.multiple) {
                $select.options[0].disabled = false;
                $select.options[0].removeAttribute('disabled');
                const $firstSumoOption = $select
                    .closest('.SumoSelect')
                    .querySelector('ul.options').children[0];
                $firstSumoOption.classList.remove('is-disabled');
                $firstSumoOption.removeAttribute('aria-disabled');
            }
        });
    }

    _resetForm() {
        this.$form.reset();
        const $sumoOptions = this.$form.querySelectorAll('.SumoSelect .opt');
        $sumoOptions.forEach((opt) => {
            opt.classList.remove('selected');
        });
    }

    // Event Handlers
    // -----------------------------------------------------------------------

    _onFormSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        this._dispatchFilterChange(event);
    }

    _onFormElementChange(event) {
        this._dispatchFilterChange(event);
    }

    _onFormReset(event) {
        event.preventDefault();
        event.stopPropagation();
        this._resetForm();
        this._dispatchFilterChange(event);
    }

    _onFacetRemoved(event) {
        const { name, value } = event.detail;
        this._unselectSelection(name, value);
        this._dispatchFilterChange(event);
    }

    _onApiRequest(event) {
        this.isLoading = true;
    }

    _onApiResponse(event) {
        this.isLoading = false;
        this.filters = event.detail?.filters;
        this._disableAllFilters();

        const possibleFilters = [].concat(...Object.values(this.filters));
        const $formElements = this.$form.querySelectorAll(
            'input[type="checkbox"], input[type="date"], input[type="radio"], select'
        );

        Array.from($formElements).forEach(($element) => {
            const nodeName = $element.nodeName?.toLowerCase();
            const type = $element.type;
            const name = $element.name;
            const possibleValuesMap = new Set(
                possibleFilters
                    .filter((entry) => entry.name === name)
                    .map((entry) => entry.value)
            );

            if (nodeName === 'select' && $element.options) {
                Array.from($element.options).forEach(($option, index) => {
                    const $sumoOption = $element
                        .closest('.SumoSelect')
                        .querySelector('.optWrapper ul.options').children[
                        index
                    ];

                    if (
                        possibleValuesMap.has(toNumber($option.value)) ||
                        possibleValuesMap.has($option.value)
                    ) {
                        $option.disabled = false;
                        $sumoOption.classList.remove('is-disabled');
                        $sumoOption.removeAttribute('aria-disabled');
                    }
                });
            } else if (nodeName === 'input') {
                if (type === 'checkbox') {
                    if (possibleValuesMap.has($element.value)) {
                        $element.disabled = false;
                    }
                } else if (type === 'radio') {
                    if (possibleValuesMap.has($element.value)) {
                        $element.disabled = false;
                    }
                } else if (type === 'date') {
                    $element.disabled = false;

                    const minVal = possibleFilters
                        .filter((entry) => entry.name === name)
                        .find((entry) => entry.min)?.min;

                    const maxVal = possibleFilters
                        .filter((entry) => entry.name === name)
                        .find((entry) => entry.max)?.max;

                    $element.min = minVal ? minVal.split('T')[0] : '';
                    $element.max = maxVal ? maxVal.split('T')[0] : '';
                }
            } else {
                console.log('Element not matching response data: ', $element);
            }
        });
    }

    // Events
    // -----------------------------------------------------------------------

    _dispatchFilterChange(nativeEvent) {
        if (this.isLoading) {
            console.log('still loading, abort...');
            return;
        }

        this.formData = new FormData(this.$form);

        const event = new CustomEvent('filter-form-change', {
            bubbles: true,
            detail: {
                formData: this.formData,
                formElement: this.$form,
                clickedTarget: nativeEvent?.target ?? '',
                isReset: nativeEvent?.target?.type === 'reset',
                isSearch: nativeEvent?.target?.type === 'search',
            },
        });
        this.dispatchEvent(event);
    }

    _dispatchFormReset() {
        const event = new CustomEvent('filter-form-reset', {
            bubbles: true,
            detail: {
                formData: this.formData,
                formElement: this.$form,
            },
        });
        this.dispatchEvent(event);
    }

    // Lifecycle
    // ------------------------------------------------------------------------

    connectedCallback() {
        super.connectedCallback();
        this.$form = this.querySelector('form');
        this.$formElements = this.$form.elements;
        this.$resetButton = this.$form.querySelector('[type="reset"]');

        this._addEventListeners();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        this._removeEventListeners();
    }
}

customElements.define('lf-filter-form', LfFilterForm);
