const getPageLanguage = (defaultLocale = 'de-DE') => {
    const locale = document.documentElement.lang
        ? document.documentElement.lang
        : defaultLocale;
    return locale.toLowerCase().split('-')[0];
};

export function prettyNumericDate(dateString, locale, options) {
    if (!dateString.length) return;

    const defaults = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    };
    const lang = locale ? locale : getPageLanguage();
    const opts = Object.assign(defaults, options);

    return new Date(dateString).toLocaleDateString(lang, opts);
}

/**
 * Returns true if the two dates represent the same
 * year, month, and day.
 */
export function isSameDate(date1, date2) {
    return date1?.toLocaleDateString() === date2?.toLocaleDateString();
}

/**
 * Returns a Date object that displays the correct year, month, and day
 * in the local time zone, given a UTC Date object as returned by input type="date".
 */
export function localDateFromUTCDate(date) {
    return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate()
    );
}

/**
 * Ensure a string of characters never exceeds the given limit.
 *
 * @param {String} string
 * @param {Number} limit
 * @param {String} append
 * @param {String} separator
 */
export function limitString(str = '', limit, append = ' …', separator = ' ') {
    if (!str.length) return '';

    if ([...str]?.length <= limit) {
        return str;
    }

    if (append.length > 0) {
        limit = limit - append.length;
        str = str.substr(0, str.lastIndexOf(separator, limit));
        str += append;

        return str;
    }

    return str.substr(0, str.lastIndexOf(separator, limit));
}

/**
 * Serialize all form data into an object.
 *
 * @param  {FormData} data The FormData object to serialize
 * @return {String} The serialized form data
 */
export function serializeFormData(data) {
    let obj = {};
    for (let [key, value] of data) {
        if (obj[key] !== undefined) {
            if (!Array.isArray(obj[key])) {
                obj[key] = [obj[key]];
            }
            obj[key].push(value);
        } else {
            obj[key] = value;
        }
    }
    return obj;
}

export function getFormData(formid) {
    var form = document.getElementById(formid);
    let formdata = {};

    Array.from(form.querySelectorAll('input, select, textarea'))
        .filter((element) => element.name)
        .forEach((element) => {
            if (element.type === 'checkbox') {
                formdata[element.name] = element.checked;
            } else if (element.options) {
                var selected = [...element.selectedOptions].map(
                    (option) => option.value
                );

                // var selected = [...element.options]
                //     .filter((option) => option.selected)
                //     .map((option) => option.value);

                if (selected.length > 1) {
                    formdata[element.name] = selected;
                } else {
                    formdata[element.name] = element.value;
                }
            } else {
                formdata[element.name] = element.value;
            }
        });
    return formdata;
}
