import { LitElement } from 'lit';

export class BaseCustomElement extends LitElement {
    /**
     * Don't render templates in the shadow DOM.
     * Rendering templates in the light DOM allows custom elements to be
     * styled globally, like regular HTML elements. It also makes custom
     * form controls considerably easier to deal with.
     * Features like encapsulated CSS and slots, however, are unavailable
     * in the light DOM.
     */
    createRenderRoot() {
        return this;
    }
}
