/**
 * @fileoverview Selects the most relevant element in a TOC depending
 * on the page’s scroll position.
 *
 * Sets `[data-active]` on the contained `<a href>` that matches
 * the heading most visible on the page.
 */
class ScrollNav {
    constructor(element) {
        this.$element = element;
        this.previousActiveAnchor = null;
        this.updateHeading = () => {};
        this.visibleHeadings = new Set();
        this.toggleButton = this.$element.querySelector(
            '.js-disclosure-button'
        );
        this.toggleButtonLabel =
            this.toggleButton.querySelector('.c-button__text');

        let requestPending = 0;

        this.observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    /** @type {HTMLElement} */
                    const target = entry.target;

                    if (entry.isIntersecting) {
                        this.visibleHeadings.add(target);
                    } else {
                        this.visibleHeadings.delete(target);
                    }

                    // After any heading becomes visible or invisible (most likely due to
                    // scroll or on startup), check which one is most active in a rAF.

                    if (requestPending) {
                        return;
                    }
                    requestPending = window.requestAnimationFrame(() => {
                        requestPending = 0;
                        this.updateHeading();
                    });
                });
            },
            { threshold: 0.5 }
        );
    }

    init() {
        const scrollingElement = document.scrollingElement;
        const containerElement = document.body.querySelector('main');

        if (!containerElement || !scrollingElement) {
            return;
        }

        const tocLinkDict = {};
        let firstLink = null;
        const links = this.$element.querySelectorAll('a[href]');

        for (const link of links) {
            const rawHref = link.getAttribute('href') ?? '';
            if (rawHref.startsWith('#')) {
                tocLinkDict[rawHref.substr(1)] = link;

                if (firstLink === null) {
                    firstLink = link;
                }
            }

            link.addEventListener('click', () => {
                link.closest('.c-scroll-nav')
                    .querySelector('.js-disclosure-button')
                    .click();
            });
        }

        // Add all headings to an IntersectionObserver.

        /**
         * @type {NodeListOf<HTMLElement>}
         */
        const headings = containerElement.querySelectorAll('[id]');
        for (const heading of headings) {
            this.observer.observe(heading);
        }

        // Find the first visible anchor element inside the article that has a
        // matching link in the TOC.
        // We assume the DOM order matches the display order.

        this.updateHeading = () => {
            /** @type {HTMLAnchorElement?} */
            let foundHeading = null;

            for (const heading of headings) {
                if (
                    this.visibleHeadings.has(heading) &&
                    heading.id in tocLinkDict
                ) {
                    foundHeading = tocLinkDict[heading.id];
                    break;
                }
            }

            if (foundHeading === this.previousActiveAnchor) {
                return;
            }

            // We don't remove `[data-active]` from a previous anchor if there's no new
            // best found heading: this is possible in long articles where there's no
            // visible headings in a large section of text.
            // This basically makes it appear as if something is always active.

            if (foundHeading) {
                if (this.previousActiveAnchor) {
                    this.previousActiveAnchor.removeAttribute('data-active');
                }
                this.previousActiveAnchor = foundHeading;
                foundHeading.setAttribute('data-active', '');
                this.toggleButtonLabel.textContent = foundHeading.textContent;
            }
        };
    }

    destroy() {
        this.observer.disconnect();

        if (this.previousActiveAnchor) {
            this.previousActiveAnchor.removeAttribute('data-active');
            this.previousActiveAnchor = null;
        }
    }
}

export { ScrollNav as default };
