import ConvertRemToPixels from '../../../scripts/modules/convertRemToPixels.js';

// TODO: handle resizes
// TODO: handle initial header fixed state, when initial scrollTop > offset

class Header extends ConvertRemToPixels(class {}) {
    constructor($module) {
        super();

        this.$module = $module;
        this.lastScrollTop = this.getScrollTop();
        this.scrollDirectionIsDown = false;

        this.isFixed = false;
        this.hasBigHeader = $module.classList.contains('c-header--big');
        this.isBig = this.hasBigHeader;
        this.isScrolling;
        this.eventHeaderHide = new Event('headerHide', {
            bubbles: true,
        });

        // Use the header’s height as the offset
        this.offset = this.convertRemToPixels(
            getComputedStyle(document.documentElement).getPropertyValue(
                '--height-header'
            )
        );

        // bind `this` to methods where needed
        this.handleScroll = this.handleScroll.bind(this);
        this.handleScrollListenerTimeout =
            this.handleScrollListenerTimeout.bind(this);
    }

    init() {
        window.addEventListener('scroll', this.handleScroll);
    }

    getScrollTop() {
        // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        return window.pageYOffset || document.documentElement.scrollTop;
    }

    handleScroll() {
        const currentScrollTop = this.getScrollTop();

        if (currentScrollTop > this.lastScrollTop) {
            if (this.scrollDirectionIsDown) {
                if (currentScrollTop >= this.offset && !this.isFixed) {
                    this.toggleFixed();
                }
                if (currentScrollTop >= this.offsetPoint) {
                    this.toggleVisibility();
                    this.$module.dispatchEvent(this.eventHeaderHide);
                }
            } else {
                this.scrollDirectionIsDown = true;
                this.offsetPoint = this.getScrollTop() + this.offset;
            }
        } else {
            if (currentScrollTop <= 0 && this.isFixed) {
                this.toggleFixed();
            }
            if (this.scrollDirectionIsDown) {
                this.scrollDirectionIsDown = false;
                this.toggleVisibility();
            }
        }
        this.lastScrollTop = currentScrollTop;
    }

    toggleSize() {
        if (this.isBig) {
            this.$module.classList.remove('c-header--big');
        } else {
            this.$module.classList.add('c-header--big');
        }
        this.isBig = !this.isBig;
    }

    toggleFixed() {
        if (this.hasBigHeader) {
            this.toggleSize();
        }
        if (this.isFixed) {
            // console.log('not fixed');
            this.$module.classList.remove('is-fixed');
            document.documentElement.classList.remove('has-sticky-header');
        } else {
            // console.log('fixed');
            this.$module.classList.add('is-fixed');
            document.documentElement.classList.add('has-sticky-header');
        }
        this.isFixed = !this.isFixed;
    }

    toggleVisibility() {
        this.offsetPoint = undefined;

        if (this.scrollDirectionIsDown) {
            // console.log('not visible');
            this.$module.classList.remove('is-visible');
            document.documentElement.classList.remove('has-visible-header');
        } else {
            // console.log('visible');
            this.$module.classList.add('is-visible');
            document.documentElement.classList.add('has-visible-header');
        }
    }

    setScrollListenerTimeout() {
        if (this.getScrollTop() > 0) {
            window.removeEventListener('scroll', this.handleScroll);
            window.addEventListener('scroll', this.handleScrollListenerTimeout);
        }
    }

    handleScrollListenerTimeout() {
        window.clearTimeout(this.isScrolling);
        this.isScrolling = setTimeout(() => {
            window.removeEventListener(
                'scroll',
                this.handleScrollListenerTimeout
            );
            window.addEventListener('scroll', this.handleScroll);
        }, 50);
    }
}

export default Header;
