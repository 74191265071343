import { html, nothing } from 'lit';
import { BaseCustomElement } from '../base-custom-element/base-custom-element';
import '../button/button.js';

class LfFilterFooter extends BaseCustomElement {
    static get properties() {
        return {
            hasError: { type: Boolean },
            isLoading: { type: Boolean },
            numberOfEntries: { type: Number, attribute: 'results' },
        };
    }

    constructor() {
        super();
        this.numberOfEntries = 0;
        this.icon = '';
        this.hasError = false;
        this.isLoading = false;
        this.isUserInitiated = false;
        this.i18n = {};
        this.locale = 'de';
        this._onApiRequest = this._onApiRequest.bind(this);
        this._onApiResponse = this._onApiResponse.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        this.resultsElement = document.querySelector('#filter-result');
        window.addEventListener('api-request', this._onApiRequest);
        window.addEventListener('api-response', this._onApiResponse);
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('api-request', this._onApiRequest);
        window.removeEventListener('api-response', this._onApiResponse);
    }

    _onFilterButtonClick() {
        this.resultsElement.scrollIntoView(true);
    }

    _onApiRequest() {
        this.isLoading = true;
    }

    _onApiResponse(event) {
        this.isLoading = false;
        this.numberOfEntries = event.detail.data.pagination.numberOfEntries;
        this.locale = event.detail.data.locale;



    }



    render() {
        if (this.numberOfEntries && this.numberOfEntries < 1) {
            return nothing;
        }


        

        const resultWordDE = this.numberOfEntries !== 1 ? `Ergebnisse` : `Ergebnis`;
        const resultWordEN = this.numberOfEntries !== 1 ? `results` : `result`;
    
        const buttonTextDE = `${this.numberOfEntries} ${resultWordDE} anzeigen`;
        const buttonTextEN = `Show ${this.numberOfEntries} ${resultWordEN}`;
        const buttonText = this.locale == 'de' ? buttonTextDE : buttonTextEN
        //.isLoading=${this.isLoading && this.isUserInitiated}

        return html`
            <lf-button
                .text=${buttonText}
                .isLoading=${this.isLoading}
                @click=${this._onFilterButtonClick}
            ></lf-button>
        `;
    }
}

customElements.define('lf-filter-footer', LfFilterFooter);
